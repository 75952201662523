.sts-form {
    .form-control {
        &:focus {
            box-shadow: none;
            border: 1px solid rgba(5, 5, 24, 0.5019607843);
            background: none;
        }
        &.is-invalid {
            background-color: rgb(247 83 122 / 5%);
            border-color: #dc3545 !important;
            background-image: url(../../assets/images/error-info.png);
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: right 13px center;
        }
        &.mat-select{
            &.is-invalid{
                background-image: none;
            }
        }
        &.mat-datepicker-input{
            &.is-invalid {
                background-image: none;
            }
        }
        &.textarea-box{
            &.is-invalid {
                background-image: none;
            }
        }
        &.is-valid {
            background-image: url("../../assets/images/check-green.svg");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }
        &:disabled{
            border: 1px solid rgb(5 5 24 / 11%) !important;
            background-color: #e9ecef80;
        }
    }
    .rm-error-icon{
        .is-invalid {
            background-image: none;
        }
    }
    .required {
        color: red;
    }
    .error-msg {
        @include fontsize-variant-1($font-size-14, $font-weight-normal, red);
    }
    input {
        border: 1px solid rgba(#050518, 30%) !important;
        width: 100%;
        @include border-radius(8px);
        height: 50px;
        // height: 45px;
        background: transparent;
        padding-left: 15px;
        @include fontsize-variant-1($font-size-16, $font-weight-normal, $black);
        &.mat-input-element{
            margin-top: 0px;
        }
        &.inactive-field{
            color: rgba(#050518, 60%);
            background: rgba(#050518, 0.06);
            border: 1px solid rgba(#050518, 10%) !important;
            cursor: default;
            pointer-events: none;
            &:focus{
                color: rgba(#050518, 60%);
                background: rgba(#050518, 0.06);
                border: 1px solid rgba(#050518, 10%) !important;
            }
        }
    }
    .otp-input-box {
        width: 50px;
        height: 50px;
        text-align: center;
        padding: 0px;
        @include fontsize-variant-1($font-size-20, $font-weight-semibold, $black-variant1);
        background: white;
        &:focus{
            background: white;
        }
    }
    .otp-error {
        input {
            border: 1px solid #F7537A !important;
            @include fontsize-variant-1($font-size-20, $font-weight-semibold, #F7537A);
            &:focus {
                border: 1px solid #F7537A !important;
            }
        }
    }
    .form-label {
        @include fontsize-variant-1($font-size-16, $font-weight-normal, $base-black-color);
        margin-bottom: 10px;
        display: inline-block;
    }
    .form-space {
        margin-bottom: 0.6rem;
    }
    .mat-form-field {
        width: 100%;
        font-family: $font-family-urbanist;
        .mat-form-field-wrapper {
            padding-bottom: 0.5rem;
            .mat-form-field-infix {
                border: none;
                padding: 0px;
                @media screen and (max-width: 767px){
                    // border-top: 0.84375em solid transparent;
                }
            }
        }
        .mat-form-field-label {
            line-height: 104px;
            // margin-top: 10px;
            // top: 3.4rem;
            top: 0px;
            padding-left: 15px;
            @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($base-black-color, 0.6));
            .mat-placeholder-required {
                display: none;
            }
        }
        .mat-form-field-underline {
            display: none
        }
        .mat-input-element {
            box-sizing: border-box;
        }
        .mat-error {
            margin-top: 10px
        }
        .mat-input-element {
            caret-color: $base-black-color
        }
    }
    .mat-select {
        border: 1px solid rgba(#050518, 30%);
        width: 100%;
        @include border-radius(8px);
        height: 50px;
        background: transparent;
        padding: 0px 15px;
        line-height: 50px;
        padding-right: 15px;
        @include fontsize-variant-1($font-size-14, $font-weight-normal, $base-black-color);
        .mat-select-value {
            font-family: $font-family-urbanist;
        }
        &.mat-select-invalid {
            .mat-select-arrow {
                color: rgba(0, 0, 0, 0.54);
            }
        }
        .mat-form-field-label {
            top: 3.6rem;
        }
        .mat-select-arrow {
            background: url("../../assets/images/down-arrow.svg");
            background-size: contain !important;
            background-repeat: no-repeat;
            width: 21px;
            height: 21px;
            border: none;
            background-color: #fff;
            z-index: 1;
            position: relative;
            margin-right: 0px;
        }
        &.ng-valid{
            .mat-select-arrow{
                // background: none;
            }
        }
        &.variant1{
            width: 300px;
            background: $white;
        }
    }
    .material-textarea {
        position: relative;
        textarea {
            border: 1px solid rgba(#050518, 30%);
            width: 100%;
            @include border-radius(8px);
            background: transparent;
            padding-left: 15px;
            padding-top: 15px;
            resize: none;
            padding-bottom: 30px;
            @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        }
        .mat-form-field-suffix {
            // right: 0px;
            // bottom: 6px;
            // background: #fff;
            // width: 98%;
            // text-align: end;
            // position: absolute;
            // padding: 7px;
            // border: 1px solid rgb(5 5 24 / 43%);
            // border-radius: 0px;
            // border-top: 0;
            // border-left: 0;
            // border-bottom-right-radius: 6px;
        }
        .mat-form-field-invalid {
            .mat-form-field-suffix {
                bottom: 7px;
                background: transparent;
            }
        }
        // &.label-md-top {
        //     .mat-form-field-label {
        //         top: 1.6rem
        //     }
        // }
    }
    .mat-checkbox {
        @include fontsize-variant-1($font-size-16, $font-weight-normal, rgba($base-black-color, 0.6));
        font-family: $font-family-urbanist;
        &.mat-checkbox-checked {
            .mat-checkbox-background {
                background-color: $base-blue-color;
            }
        }
        .mat-checkbox-ripple {
            display: none;
        }
        .mat-checkbox-label {
            @include fontsize-variant-1($font-size-16, $font-weight-medium, $base-black-color);
        }
        .mat-checkbox-layout {
            display: flex;
        }
        .mat-checkbox-inner-container{
            width: 20px;
            height: 20px;
            .mat-checkbox-frame{
                // border-radius: 4px;
                border-color: #050518;
                border-width: 1px;
                @media screen and (max-width: 767px){
                    border-color: white
                }
            }
        }
    }
    .search-input {
        outline: none;
        input {
            background: $white;
            padding-left: 2.5rem;
        }
        .mat-form-field-label {
            padding-left: 2.5rem;
            // top: 1.9rem;
        }
        &.size-lg {
            width: 23.75rem;
        }
        &::-webkit-input-placeholder {
            @include fontsize-variant-1($font-size-16, $font-weight-normal, rgba($black-variant1, 0.4));
        }
    }
    .no-label{
        .mat-form-field-label{
            line-height: 50px !important;
        }
    }
    .iti{
        width: 100%;
    }
    .ngx-mat-tel-input-container {
        display: flex;
        gap: 10px;
        position: relative;
        .country-selector {
            width: 125px !important;
            position: unset;
            border: 1px solid rgba(#050518, 30%);
            width: 110px;
            height: 50px;
            border-radius: 8px;
            background-image: url(../../assets/images/down-arrow.svg);
            background-size: 23px;
            background-position: 89px;
            background-repeat: no-repeat;
            text-align: left;
            padding-left: 15px;
            opacity: 1 !important;
            bottom: 8px !important;
            .country-selector-code{
                font-size: 1rem;
                font-weight: 400 !important;
                color: #111111;
            }
        }
        input {
            padding-left: 15px !important;
            @include fontsize-variant-1($font-size-16 !important, $font-weight-medium !important, $black-variant1 !important);
        }
    }
    .phnum-invalid {
        background-color: rgb(247 83 122 / 5%);
        border-color: #dc3545 !important;
        background-image: url(../../assets/images/error-info.png);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: right 13px center;
        .mat-input-element{
            background-color: rgb(247 83 122 / 5%);
        border-color: #dc3545 !important;
        background-image: url(../../assets/images/error-info.png);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: right 13px center;
        }
    }
}
.mat-checkbox-layout{
    margin-top: -3px;
}
.search-input-variant1 {
    .mat-form-field {
        width: 100%;
        padding: 0px 20px 0px 20px;
        .mat-form-field-underline {
            display: none;
        }
        .mat-input-element {
            border: 1px solid rgba(5, 5, 24, 0.5);
            border-radius: 8px;
            height: 41px;
            line-height: 40px;
            padding-left: 15px;
            width: 90%;
            font-family: "Urbanist", sans-serif;
            .mat-form-field-label {
                margin-top: 14px;
                padding-left: 15px
            }

        }
        .mat-form-field-label{
            padding-left: 15px;
        }
    }
.mat-form-field.mat-focused .mat-form-field-label{
    color: rgba(0, 0, 0, 0.54)
    }
}
.customPlaceholder{
    .mat-form-field-label-wrapper{
        span{
            position: relative;
            left: 150px;
        }
    }
    &:focus-within{
        .mat-form-field-label-wrapper{
            span{
                display: none;
            }
        }
    }
}
.upload-image {
    .mat-form-field{
        font-family: $font-family-urbanist;
    }
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        padding: 0px;
        border: none;
    }
    .mat-form-field-wrapper {
        padding: 0px;
    }
    .edit-icon {
        width: 24px;
        height: 24px;
        @include border-radius(100px);
        background: $base-blue-color;
        text-align: center;
        outline: 3px solid $white;
        line-height: 19px;
    }
}
.mat-option {
    font-family: $font-family-urbanist;
}
.black-label {
    .mat-form-field-label {
        @include fontsize-variant-1($font-size-14, $font-weight-medium !important, $base-black-color !important);
    }
    .mat-select-value-text {
        // font-weight: $font-weight-semibold !important;
        color: #000;
    }
}
.mat-dialog-container {
    min-width: 400px;
}
//mat-option-text
.mat-option-text {
    color: $black;
}
// password asterik key change from dot
/* Use the css below to change the password input symbol */
@font-face {
    font-family: 'star';
    src: url('../../assets/fonts/star.ttf?59697915') format('truetype');
    src: url('../../assets/fonts/star.woff2?59697915') format('woff2'),
        url('../../assets/fonts/star.woff?59697915') format('woff'),
        url('../../assets/fonts/star.ttf?59697915') format('truetype'),
        url('../../assets/fonts/star.svg?59697915#star') format('svg');
    // font-weight: normal;
    // font-style: normal;
    // url('../../assets/fonts/star.eot?59697915#iefix') format('embedded-opentype'),
}
input[type="password"] {
    font-family: "star";
    font-style: normal;
    font-weight: 100;
    font-size: 7px;
    speak: never;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 2px;
    &.ng-invalid{
        font-size: unset;
    }
}
.nospace-matformwrapper .mat-form-field-wrapper{
    padding-bottom: 0px !important;;
}
//textarea count
.textarea-with-count{
    position: relative;
    margin-bottom: 30px;
    textarea{
        border-bottom: none !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        &.is-invalid + .count{
            border: 1px solid #dc3545;
            border-top: none;
            background: rgba(247, 83, 122, 0.05);
        }
    }
    .count{
        position: absolute;
    margin-bottom: -28px;
    right: 0px;
    background: #fff;
    width: 100%;
    border: 1px solid rgba(#050518, 30%);
    padding: 6px;
    bottom: 0px;
    border-top: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    }
    &.no-label{
        .mat-form-field-label{
            line-height: 43px !important;
        }
    }
}
.mat-select-panel{
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
}
.mat-form-field-label-wrapper {
    top: 0px;
    padding-top: 0px;
}
.card-shadow{
    box-shadow: 0px 4px 45px 0px #1A2A610F;
}
.active-radio-box{
    border: 1.5px solid #0977C5;
}
.transform-arrow{
    transform: rotate(180deg);
}
.payment-table{
    table{
        tr{
            border-bottom: 1px solid #dee2e6;
            td{
                padding: 10px 0px;
            }
        }
    }
}
//payment option card type  choosing css
.card-type{
    height: 56px;
    width: 16.375rem;
}
//custom-popover
.custom-popover{
    position: relative;
    .custom-popover-card{
        position: absolute;
        box-shadow: 0px 4px 45px 0px rgba(26, 42, 97, 0.1019607843);
        width: 720px;
        border-radius: 20px;
        margin-top: 20px;
        z-index: 1;
        @media(max-width: 992px){
            max-width: 450px;
             left: -130px;
        }
        @media(max-width: 767px){
            max-width: 340px;
             left: -130px;
        }
        &:before{
            position: absolute;
            top: -7px;
            content: "";
            left: 25px;
            right: 0;
            border-top: 20px solid #ffffff;
            border-left: 20px solid transparent;
            transform: rotate(-45deg);
            width: 20px;
            height: 20px;
        }
    }
}


// safari only
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .safari_only {
        .mat-form-field-label{
            line-height: 108px !important;
        }
        .mat-select-arrow{
            position: relative;
            margin-top: -5px;
        }
    }
}}
//pdf-input
.pdf-input input:focus{
    outline: none;
    box-shadow: none;
    border-bottom: 2px dashed #000;
}
.pdf-input input{
    caret-color: $base-black-color
}
.pdf-input input{
    border: 1px solid rgba(5, 5, 24, 0.3) !important;
    width: 300px;
    @media(max-width: 767px){
        width: unset;
        margin: 5px 5px;
    }
    margin-bottom: 15px;
    border-radius: 8px;
    height: 36px;
    background: transparent;
    padding-left: 15px;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    margin: 5px 10px;
}
.pdf-checkbox{
    width: 18px !important;
    height: 18px !important;
    margin: 0px !important;
    padding: 0px;
    margin-right: 5px !important;
    accent-color: #0977C5;
}
.pdf-checkbox:checked{
    accent-color: #0977C5;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.chatbot-form .sts-form .mat-form-field .mat-form-field-label{
    line-height: 96px !important;
}