// map css
#myMap{
    height: calc(100vh - 0px);
    display: block;
    .map-container{
        width: 100% !important;
        border-radius: 20px;
        height: 100% !important;
    }
    .gm-style-mot{
        display: none;
    }
    .gm-style-moc{
        background-color: transparent !important;
    }
}
.live-map{
    .map-container{
        border-radius: 20px;
        margin: 15px 0px;
    }
}
.mapPopup{
    .mat-dialog-container{
        padding: 0px !important;
    }
    .search-input{
        margin-top: 45px;
    }
}