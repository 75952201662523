.sts-tab {
    .mat-ink-bar{
        display: none;
    }
    .mat-tab-header{
        border-bottom: none;
    }
    .mat-tab-group{
        font-family: $font-family-urbanist;
    }
    &.tab-variant1{
        .mat-tab-group{
            .mat-tab-label {
                border: 1px solid $base-blue-color;
                // background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
                //     linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
                @include fontsize-variant-1($font-size-18, $font-weight-semibold, $base-blue-color);
                @include border-radius(80px);
                min-width: 10.275rem;
                height: 3.263rem;
                opacity: 1;
                font-family: $font-family-urbanist;
                margin-right: 1rem;
            }
            .mat-tab-label-active {
                background: $base-blue-color;
                @include fontsize-variant-1($font-size-18, $font-weight-semibold, $white);
            }
            .mat-ink-bar{
                display: none;
            }
            .mat-tab-header{
                border: none;
            }
        }
    }
    &.tab-variant2{
        .mat-tab-header{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.55) 100%);
            padding: 15px;
            @include border-radius(20px);
        }
        .mat-tab-labels{
            justify-content: center;
            .mat-tab-label{
                opacity: 1;
                padding: 0px;
                padding-right: 7.625rem;
                .mat-label-text{
                    .mat-label-number{
                        @include fontsize-variant-1($font-size-14, $font-weight-semibold,
                    rgba(#050518, 60%));
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        border:1.5px solid #E1E1E3;
                        @include border-radius(100px);
                    }
                    @include fontsize-variant-1($font-size-14, $font-weight-semibold,
                    rgba(#050518, 60%));
                    border:1.5px solid #E1E1E3;
                    @include border-radius(20px);
                    padding-right: 20px;
                    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
                    linear-gradient(0deg, #E1E1E3, #E1E1E3);
                    z-index: 1;
                    border-left: none;
                }
                .completedTab {
                    color: #06A680 !important;
                    border:1px solid #06A680;
                    border-left: none !important;
                    .mat-label-number {
                        background: #06A680 !important;
                        border: none;
                    }
                    &:before{
                        background: #06A680;
                    }
                }
            }
            .mat-ripple{
                overflow: visible
            }
            .mat-tab-label-active{
                .mat-label-text{
                    .mat-label-number{
                        @include fontsize-variant-1($font-size-14, $font-weight-semibold, #06A680);
                        // background: rgba(#06A680, 0.1);
                        background: #ddf4ef;
                        border:1px solid #06A680;
                        border-left: none;
                    }
                    @include fontsize-variant-1($font-size-14, $font-weight-semibold,rgba(#050518, 60%));
                    border:1px solid #06A680;
                }
            }
        }
        .mat-tab-label-content{
            font-family: $font-family-urbanist;
        }
        .mat-tab-list{
            flex-grow: 0;
            margin: auto;
            .mat-label-text{
                position: relative;
                &:before {
                    position: absolute;
                    background: #ccc;
                    height: 1px;
                    width: 90%;
                    margin: auto;
                    top: 20px;
                    content: "";
                    left: calc(100% - 0px);
                }
            }
            .mat-tab-label{
                &:last-child{
                    .mat-label-text{
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.tab-variant3{
      .mat-tab-header{
        background: rgba( #0977C5,6%);
      @include border-radius(8px);
        overflow: visible;
        .mat-tab-label-container{
            overflow: visible;
            padding-bottom: 0px;
            .mat-tab-labels{
                border-bottom: 1px solid rgba($base-blue-color, 50%);
                .mat-tab-label{
                    font-family: $font-family-urbanist;
                    opacity: 1;
                    min-width: unset;
                    padding: 0 13px;
                    .mat-tab-label-content{
                        @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($black-variant1,50%));
                    }
                  }
                  .mat-tab-label-active{
                      overflow: visible;
                      .mat-tab-label-content{
                          position: relative;
                          @include fontsize-variant-1($font-size-16, $font-weight-medium, $black-variant1);
                          &:after{
                            position: absolute;
                            bottom: -14.7px;
                            width: 100%;
                            right: 0px;
                            height: 4px;
                            border-radius: 8px;
                            content: "";
                            left: 0px;
                            background: $base-blue-color;
                            margin: auto;
                        }
                        }
                  }
              }
        }
      }
    }
    .mat-tab-label-container{
        padding-bottom: 10px;
    }
}


