.sts-card {
    background: $white;
    padding: 16px;
    @include border-radius(20px);
    display: block;
    box-shadow: 0px 4px 45px 0px #1A2A610F;
    .mat-card {
        font-family: $font-family-urbanist;
        border-radius: 30px;
        background: $white;
        box-shadow: none;
        padding: 0px;
        .mat-card-header-text {
            display: none;
        }
    }
    &.variant-1 {
        box-shadow: 0px 4px 45px 0px #1A2A610F;
    }
    &.variant-2{
        @include border-radius(30px);
    }
    &.width-variant1{
        width: 23.875rem;
        @media(max-width: 992px){
            width: 100%;
        }
    }
    &.width-variant2{
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
.profile-info {
    .title {
        padding: 0.5rem 0;
        @extend .border-bottom-variant1;
    }
}
.schedule-user {
    background: rgb(255 255 255 / 35%);
    padding: 10px;
    .user-image {
        width: 56px;
        height: 56px;
        object-fit: contain;
        object-position: center center;
        border-radius: 100%;
        background: $white;
    }
}
.card-height{
    height: 315px;
    overflow-y: auto;
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
    @media screen and (max-width: 992px){
        height: unset;
    }
    // &::-webkit-scrollbar-thumb{
    //     background: #eee;
    // }
    &.profile-card-height{
        height: 345px;
        @media screen and (max-width: 992px){
            height: unset;
        }
    }
    &.card-height-md{
        height: 345px;
        @media screen and (max-width: 992px){
            height: unset;
        }
    }
    &.card-height-xl{
        // height: 430px;
        height: 100%;
        overflow: visible;
        @media screen and (max-width: 992px){
            height: unset;
        }
    }
    &.fee-payment-card-height{
        height: 132px;
        @media screen and (max-width: 992px){
            height: unset;
        }
    }
    &.news-card-height{
        height: 360px;
        @media screen and (max-width: 992px){
            height: unset;
        }
    }
}
.card-height-no-message{
    height: 268px;
    @media(max-width: 992px){
       height: 100%;
    }
}
.profile-widget-content-height{
    // height: 210px;
    // height: 191px;
    overflow-y: auto;
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
}
.errmsg-cardheight
{
height: 362px;
}
.errmsg-cardheight-contact{
    height: 332px;
}
.profile-card-height-no-message{
    height: 140px;
    @media(max-width: 992px){
        height: unset;
    }
}
.sibling-card-height-no-message{
    height: 298px;
    @media(max-width: 992px){
        height: 100%;
     }
}
.mobile-dashboard-widgets{
    .profile-widget-content-height{
        height: unset;
    }
    .sts-card,.contact-widget{
        height: 100%;
    }
    .graph-widget{
        .sts-card{
            height: unset;
        }
    }
}
.logo-bg-blue {
    width: 50px;
    height: 50px;
    background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
        linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
    @include border-radius(8px);
    text-align: center;
    line-height: 50px;
    max-width: 50px;
    flex: 1 1 50%;
}
.no-payment-detail{
    .graph-widget{
        height: 100%;
        .row{
            height: 100%;
        }
    }
    .report-info{
        height: 100% !important;
    }
    .no-payment-detail-card{
        height: 47%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
    }
}