// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@include mat-core();
$sts-parent-portal-primary: mat-palette($mat-indigo);
$sts-parent-portal-accent: mat-palette($mat-pink, A200, A100, A400);
$sts-parent-portal-warn: mat-palette($mat-red);
$sts-parent-portal-theme: mat-light-theme((color: (primary: $sts-parent-portal-primary, accent: $sts-parent-portal-accent, warn: $sts-parent-portal-warn, )));
@include angular-material-theme($sts-parent-portal-theme);

/* You can add global styles to this file, and also import other style files */

$fa-font-path: "node_modules/font-awesome/fonts" !default;

i[class^="fa-"],
ion-icon[class^="fa-"] {
    font-family: "FontAwesome" !important;
}


// sass includes

@import 'sass/main.scss';


//rtl direction for arabic translation
html[dir="rtl"] {
    direction: rtl;
}