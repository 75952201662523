.gradient-border {
    background: linear-gradient(to top, transparent, rgba(0, 0, 0, 12%), transparent);
    width: 1px;
}
.gradient-border1{
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) -4.49%, rgba(217, 217, 217, 0) -4.49%, rgba(237, 237, 237, 0.312) 48.45%, rgba(237, 237, 237, 0) 100%);
width: 100%;
}
.gradient-border2{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(237, 237, 237, 0.39) 46.97%, rgba(217, 217, 217, 0) 100%);
width: 1px;
}
.gradient-border3 {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 51.25%, rgba(0, 0, 0, 0) 99.22%);
    width: 100%;
    height: 1px;
}
.border-bottom-variant1{
    border-bottom: 1px solid rgba(#050518, 0.1);
}
.border-bottom-variant2{
    border-bottom: 1px dashed $base-blue-color;
}
.border-top-variant1{
    border-top: 1px solid rgba(#050518, 0.1);
}
.border-variant2{
    border: 1px solid rgba($white, 0.2);
}
.border-blue{
    border: 1px solid $base-blue-color;
}
.border-bottom-blue{
    border-bottom: 1px solid $base-blue-color;
}
.border-variant3{
    border: 2px solid $base-blue-color;
}
.s-border-top{
    border-top: 2px solid rgba(#050518, 0.06)
}
.border-dotted{
    border: 2px dashed $base-blue-color;
}
.border-left{
    border-left: 1px solid rgba(#050518, 10%);
}
//border radius
.border-radius8{
    @include border-radius(8px);
}
.border-radius12{
    @include border-radius(12px);
}
.border-radius20{
    @include border-radius(20px);
}
.border-radius24{
    @include border-radius(24px);
}
