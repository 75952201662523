.mat-dialog-container{
    border-radius: 30px !important;
    box-shadow: none !important;
    padding: 20px !important;
}
.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
}
.cdk-overlay-pane{
    background: transparent;
}
.mat-button-focus-overlay {
    background-color: transparent !important;
}
// dialog scroll
.custom-modalbox {
    &.rm-padding{
        .mat-dialog-container{
            padding: 0px !important;
        }
    }
    .mat-dialog-container {
        // padding: 0px !important;
        overflow: unset;
        @media screen and (max-width: 767px){
            width: 90%;
            margin: auto;
        }
        .mat-dialog-title {
            padding: 20px;
            font-family: $font-family-urbanist;
        }
        .mat-dialog-content {
            margin: 0px;
            @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
            @media screen and (max-width: 767px){
                max-height: 40vh;
            }
        }
        .mat-dialog-actions {
            margin: 0px;
            padding: 10px 0px 25px 0px;
            box-sizing: border-box;
        }
    }
    .success-popup{
        .mat-dialog-actions {
            padding: 0px;
        }
        .success-image{
            width: 160px;
        }
    }
    .modal-close {
        position: absolute;
        right: 10px;
        top: 10px;
        background: linear-gradient(122.84deg, rgba(18, 31, 60, 0.95) 1.51%, rgba(23, 123, 169, 0.95) 100%);
        @include border-radius(16px);
        width: 42px;
        height: 42px;
        z-index: 1;
        line-height: 40px;
    }
    &.rm-padding-matcontainer{
        .mat-dialog-container{
            padding: 0px !important;
        }
    }
}
.cdk-overlay-backdrop-showing{
    z-index: 1000;
}
.cdk-overlay-pane{
    z-index: 1090;
}
.cdk-global-overlay-wrapper{
    justify-content: center;
    align-items: center;
}

// Mobile menu dialog
@media screen and (max-width: 992px){
    .cdk-overlay-backdrop-showing{
        z-index: -1;
    }
    .mobile-menu-modalbox{
        height: 100%;
        z-index: 100000;
        .mat-dialog-container{
            width: 350px;
            border-radius: 0px !important;
            .overflow-submenu{
                // height: 500px;
                overflow-y: auto;
                .submenu-list{
                    padding-bottom: 10px;
                    position: relative;
                    &:after{
                        position: absolute;
                        bottom: 0px;
                        right:0px;
                        border-bottom: 1px solid rgba(#050518, 10%);
                        content: "";
                        width: 82%
                    };
                }
            }
        }
    }
    .cdk-global-overlay-wrapper.dialog-wrapper {
        align-items: start !important;
        justify-content: start !important;
      }
}
.searchPopup {
    input {
        background: white;
        padding-left: 40px !important;
    }
    .mat-dialog-container {
        background: transparent;
    }
    .mat-form-field-label {
        line-height: 50px !important;
        padding-left: 40px !important;
    }
    .search-list{
        &.search-list-height{
            height: 295px;
        }
        position: absolute;
        background: #fff;
        width: 360px;
        border-radius: 20px;
        padding: 0px 15px 15px 15px;
        box-shadow: 0px 4px 45px 0px #1A2A610F;
        overflow-y: auto;
        @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        li{
            border-bottom: 1px solid rgba(#050518, 10%);
            padding: 10px 0px;
        }
    }
}
.searchPopup input{
        border: 1px solid rgba(5, 5, 24, 0.3) !important;
      width: 100%;
      border-radius: 8px;
      height: 50px;
      background: white !important;
}
.cdk-global-overlay-wrapper.search-wrapper {
    justify-content: end !important;
    align-items: start !important;
    padding-top: 4.8rem !important;
    padding-right: 4rem !important;
  }
//search list in map
.search-map-list{
        background: #fff;
    text-align: left;
    @include border-radius(20px);
    padding: 15px;
    &.overflow-scroll{
        height: 250px;
         overflow-y: auto;
         @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
         overflow-x: hidden;
         width: 100%;
    }
    li{
        border-bottom: 1px solid #ccc;
        padding: 10px;
        &:last-child{
            border-bottom: none;
        }
    }
}
// dialog scroll
.dialog-scroll{
    height: 400px;
    overflow-y: auto;
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
    overflow-x: hidden;
    width: 100%;
}