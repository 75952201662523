
.footer{
    padding: 0px 45px;
    .footer-links {
        ul {
            li {
                margin-right: 1.5rem !important;
            }
        }
    }
}