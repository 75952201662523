.chatbot-container {
    width: 500px;
    position: fixed;
    background: #fff;
    right: 30px;
    bottom: 0px;
    height: 510px;
    z-index: 1000;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 20px 20px 50px 0px #193d8329;
    @media(max-width: 767px) {
      max-width: 90% !important;
      right: 24px !important;
    }
    .chat-header{
        position: absolute;
    top: 0px;
    padding: 10px;
    z-index: 1;
    right: 0px;
    left: 0px;
    font-family: "Urbanist", sans-serif;
    border: 1px solid #E8E8E8;
    border-radius: 20px 20px 0px 0px;
    padding: 20px;
    .supertext{
      font-size: 18px;
      font-weight: 600;
      color: #050518;
      .count{
          width: 25px;
          height: 18px;
          background:#F7537A;
          color: #fff;
          border-radius: 12px;
          font-size: 12px;
          display: inline-block;
          text-align: center;
      }
    }
    .subtext{
        font-size: 14px;
        color: rgb(5 5 24 / 60%);
        font-weight: 400;
    }
    }
    .k-chat {
        box-shadow: 0px 0px 5px 0px rgba(25, 61, 131, 0.2);
        width: 500px;
        height: 100%;
        border-radius: 20px 20px 0px 0px;
        font-family: "Urbanist", sans-serif;
        padding-top: 100px;
        background: #fff;
        @media(max-width: 767px) {
          max-width: 100% !important;
        }
        .header-style {
            width: 100%;
            height: 85px;
            border: 1px 0px 0px 0px;
        }
        .k-timestamp{
            font-size: 14px;
            color: #050518;
            opacity: 0.6;
            font-weight: 400;
        }
        .k-message-list{
            background: #fff;
            @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        }
    }
}
//chatbox school list
.school-list-overflow{
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
}
.chatbot-searchbox{
    .mat-form-field-appearance-legacy .mat-form-field-wrapper{
        padding-bottom: 0px;
    }
    .mat-form-field-infix{
        position: relative;
        &::before{
            position: absolute;
            content: "";
            top: 15px;
            left: 8px;
            width: 24px;
            height: 24px;
            background-size: contain;
            transform: rotate(-90deg);
            background: url(../../assets/images/search.svg);
        }
    }
    .mat-form-field-label{
        font-family: "Urbanist", sans-serif;
        font-size: 14px;
        margin-top: 2px;
        padding-left: 40px !important;
    }
    .mat-input-element{
        padding-left: 40px !important;
    }
}
kendo-chat-suggested-actions{
    display: none !important;
}
.k-chat .k-alt .k-bubble .chat-text{
    background: #0977C5;
    border: none;
    box-shadow: none;
    color: #fff;
    border-radius: 12px;
    border-top-right-radius: 2px;
    display: inline-block;
}
.k-chat .k-message-group.k-alt .k-only .k-bubble .chat-text{
    border-radius: 12px;
    border-top-right-radius: 2px;
}
.k-chat .k-chat-bubble,.k-chat .k-bubble  .chat-text{
    background:#F2F9FF;
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    color: #050518;
    font-weight: 400;
}
.k-chat .k-bubble  .chat-text{
    border-radius: 12px;
    padding: 10px;
}
// .k-chat .k-alt .k-bubble > .chat-text{
//     background: transparent;
//     border: none;
//     box-shadow: none !important;
//     font-size: 16px;
//     color: #fff;
//     font-weight: 400;
// }
.k-chat .k-alt .k-bubble  .chat-text{
    border-top-right-radius: 2px;
    border-bottom-right-radius: 12px;
}
.k-chat .k-selected .k-chat-bubble:focus{
    box-shadow: none;
}
.k-chat .k-message-group:not(.k-alt) .k-only .k-bubble  .chat-text{
    border-top-left-radius: 2px;
    border-bottom-left-radius: 12px;
}
.k-chat .k-message-time{
    opacity: 1;
    display: none;
}
.chat-timestamp{
    font-size: 14px;
    color: rgba(5, 5, 24, 0.6);
    font-weight: 400;
    margin-top: 15px;
    position: unset;
    order: 2;
    margin: 0px !important;
    margin-top: 20px !important;
}
.k-chat .k-message{
    flex-direction: column;
    // padding-left: 40px;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
}
.k-chat .k-alt .k-message{
    align-items: end;
}
.k-chat .k-message-group.k-alt .k-bubble .chat-wrapper{
    margin-left: auto;
}
.k-chat .k-message:before{
    position: absolute;
    left:0px;
    background: url(../../assets/images/chatbot-1.svg);
    content: "";
    width: 32px ;
    height: 32px;
    background-size: contain;
}
.k-chat .k-quick-reply:hover, .k-chat .k-quick-reply.k-hover ,.k-chat .k-quick-reply{
    border-color: #0977C5;
    color: #0977C5;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 9px;
}
.k-chat .k-message-box{
    width: 90%;
    margin: auto;
    margin-bottom: 15px;
    border: 1px solid #05051899 !important;
    opacity: 1;
    height: 56px;
    border-radius: 40px !important;
    padding: 0px 20px;
    font-size: 16px;
    color: #05051899;
    font-weight: 400;
    input{
        border: none;
    }
}
.rm-wrapper-height{
    height: unset;
    .k-chat{
        padding-top: 80px;
    }
}
.k-chat .k-message-box .k-button svg{
    display: none;
}
.k-chat .k-message-box .k-button{
    background-image: url(../../assets/images/send-icon.png) !important;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.k-chat .k-message-box .k-input-solid:focus, .k-input-solid.k-focus{
    box-shadow: none;
    border: none;
}
.k-chat .k-message-box .k-button{
    box-shadow: none !important;
    border: none;
    outline: none;
}
.k-chat .k-message-group.k-alt .k-message::before{
    display: none;
}
.k-chat .k-quick-reply:focus, .k-chat .k-quick-reply.k-focus{
    box-shadow: none;
}
.k-chat.minimized{
    height: 0px;
}
.hidden-chatbox{
    display: none;
}
.k-chat .k-message-group{
    max-width: unset;
    width: 100%;
}
.chat-wrapper{
    max-width: 80%;
    padding-left: 40px;
    @media(max-width: 767px){
      max-width: 100% !important;
    }
}
.k-chat .k-message,.k-chat .k-bubble{
    width: 100%;
}
.end-chat{
    text-align: center;
}
//chatbot stle
.chatbot-form{
    .mat-select-v1{
        height: 40px;
        background: #fff;
        line-height: 36px;
    }
    .mat-input-element{
        height: 40px;
        background: #fff !important;
        line-height: 36px !important;
    }
    .btn-chatbot{
        min-width: 8.5rem !important;
        height: 30px !important;
    }
    .btn-chatbot-v1{
        min-width: 9rem !important;
    }
    .sts-datepicker .mat-form-field-flex .mat-icon-button{
        margin-top: -7px;
    }
    .sts-form .ngx-mat-tel-input-container .country-selector{
        height: 40px;
        background: #fff !important;
        line-height: 36px !important;
        @media(max-width: 767px) {
          max-width: 95px !important;
        }
    }
}
.custom-modal{
    position: fixed;
    bottom: 0px;
    right: 30px;
    left: unset;
    width: 500px;
    z-index: 1;
    padding: 70px 25px 0px 25px;
    background: rgba(8, 31, 48, 0.4901960784);
    display: flex;
    justify-content: end;
    align-items: end;
    .inner-wrapper{
        background: #fff;
        border-radius: 20px 20px 0px 0px;
        width: 100%;
        .mat-dialog-actions{
            margin-bottom: -5px;
        }
    }
    .school-list-overflow{
        height: 151px;
        overflow-y: auto;
    }
}
// full-screen-chatbot
.full-screen-chatbot{
    width: 100%;
    height: calc(100vh - 90px);
    right: 0px;
    border-radius: 0px;
    top: 0;
    .k-chat{
        max-width: 100%;
        width: 100%;
        border-radius: 0px;
        z-index: 100;
        position: relative;
        padding-top: 0px;
        box-shadow: none;
    .chat-wrapper{
        max-width: fit-content;
    padding-left: 40px;
    width: 45%;
    }
    }
    .k-chat .k-message-group.k-alt .k-bubble .chat-wrapper{
        .link-title-variant2{
            color: #fff;
        }
    }
    .chat-header{
        border-radius: 0px;
        z-index: 3;
        position: relative;
    }
    .custom-modal{
        width: 100% !important;
    height: 100%;
    right: unset !important;
    top: 90px;
    left: 0px !important;
    align-items: start !important;
    justify-content: center !important;
    .inner-wrapper{
        width: 40% !important;
        height: 100%;
    }
    .school-list-overflow{
        height: calc(100vh - 370px) !important;
    }
    }
}



