.breadcrumb{
        .breadcrumb-item{
            @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($base-black-color, 0.6));
            padding-left: 0px;
            a{
                @include fontsize-variant-1($font-size-14, $font-weight-normal, $base-blue-color);
                text-decoration: none;
                cursor: pointer;
                &:hover{
                    color: $base-blue-color;
                }
            }
            &:before {
                content: "" !important;
                background: url("../../assets/images/right-arrow.svg");
                width: 25px;
                height: 16px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                margin-top: 3px;
            }
        }
}