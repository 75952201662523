.mat-tooltip{
    overflow: visible !important;
    &.black-tooltip{
        background-color:#0000008f;
        position: relative;
        &:after{
            position: absolute;
            content: "";
            top: -5.5px;
            left: 9px;
            border-left: 12px solid #0000008f;
            border-bottom: 12px solid transparent;
            transform: rotate(45deg);
        }
    }
}