// Large desktops, laptops
@media screen and (max-width: 1200px) {
    // Login
    .container-base-variant2 {
        padding: 0 50px;
    }
    .container-base-variant1 {
        padding: 0 45px;
    }
}
// small Laptop, tab
@media screen and (max-width: 992px) {
    .sts-landing{
        .padding-base{
            padding: 50px;
        }
        .banner-padding-base {
            .fs52-variant1 {
                @include fontsize-variant-1($font-size-32, $font-weight-semibold, $white);
                line-height: 38px;
            }
        }
        .topbar {
            padding: 15px 20px;
            .login-btn button {
                min-width: 5rem;
            }
        }
        .landing-service{
            .nav-tabs{
                .nav-item:after{
                    right: -15px;
                }
            }
        }
    }
    .sts-form{
        .otp-input-box{
            width: 38px;
            height: 38px;
        }
    }
    .fp-main-content{
        .lr-rightside{
            .container-base-variant2{
                padding: 0px 25px;
            }
        }
    }
}
// Mobile size (PORTRAIT)
@media screen and (max-width: 767px) {
    // LANDING PAGE
    .sts-landing {
        overflow-x: hidden;
        .hero-banner {
            background-position: top;
            .banner-content {
                padding-top: 85px;
            }
            .banner-padding-base {
                padding: 85px 45px 40px 45px;
            }
            .bus-icon {
                width: 24px;
                height: 24px;
            }
        }
        .image-shadow {
            .shadow {
                display: none;
            }
        }
        .logo {
            img {
                max-width: 100%;
            }
        }
        .landing-btn {
            &.landing-secondary-btn {
                min-width: unset !important;
            }
        }
        .topbar {
            padding: 12px;
            top: 10px;
            .login-btn button {
                min-width: 5rem;
            }
        }
        .fixed-top {
            top: 0px;
            .login-btn-scrolled {
                button {
                    min-width: unset !important;
                }
            }
            .logo-image{
                max-width: 100%;
            }
        }
        .banner-padding-base {
            padding: 100px 30px 50px 20px;
            text-align: center;
            .fs52-variant1 {
                @include fontsize-variant-1($font-size-32, $font-weight-semibold, $white);
                line-height: 38px;
                text-align: center;
            }
        }
        .horizontal-text {
            font-size: 6.125rem;
            letter-spacing: 0px;
            top: -10px;
        }
        .padding-base {
            padding: 70px 25px;
            padding-bottom: 50px;
        }
        .vertical-text {
            display: none;
        }
        .owl-dots {
            text-align: left;
        }
        // about
        .landing-about {
            .landing-bg {
                background-image: none;
            }
        }
        // md message
        .landing-md-message {
            .padding-base {
                padding-top: 70px;
            }
            .quote-image {
                left: 0px;
            }
        }
        .landing-awards {
            .owl-dots {
                margin-top: 0px !important;
                display: block;
                .owl-dot {
                    span {
                        background: transparent;
                        border: 1px solid $base-blue-color;
                    }
                    &.active {
                        span {
                            background: $base-blue-color;
                        }
                    }
                }
            }
        }
        .landing-offers {
            .sts-carousel-offers {
                margin-top: 1rem;
                .owl-dots {
                    position: unset;
                }
            }
        }
        .landing-location {
            .location-inner-section address {
                height: unset;
            }
            .owl-item .carousel-item-content {
                margin-right: 0px;
            }
        }
        .mobile-footer {
            text-align: center;
        }
        #contactsection {
            background-image: none;
            .circle-bg {
                &::before {
                    display: none;
                }
            }
        }
        .landing-service{
            .tab-content{
                padding-bottom: 0px;
            }
        }
    }
    .sts-form{
        .material-textarea{
            .mat-form-field-suffix{
                width: 98%;
                right: 0px;
                border: 1px solid rgb(5 5 24 / 38%);
                bottom: 6px;
                border-radius: 0px;
                border-top: 0px;
                border-left: 0px;
                border-bottom-right-radius: 8px;
            }
        }
    }
    // Login
    .sts-login {
        .container-base-variant2 {
            padding: 0px 25px;
        }
        //    login page
        .login-right-mobile {
            background: url("../../assets/images/login-left-bg.png?v=1");
            background-size: cover;
            height: 100%;
            background-position: center center;
            .register-link {
                color: $white;
                span {
                    color: $white;
                    text-decoration: underline;
                }
            }
            .show-hide-btn {
                background: rgba($black, 16%);
                color: $white;
                font-size: $font-size-10;
            }
            .login-logo {
                padding: 100px 0px 40px;
            }
        }
        .h-mob-unset {
            height: unset !important;
        }
        .sts-form {
            .form-control {
                &:focus {
                    border-color: $white;
                }
                .caret-color {
                    color: $white;
                }
            }
            .form-label,
            .link-title-variant2,
            .mat-input-element {
                color: $white;
                caret-color: $white;
            }
            input,
            input:focus {
                border-color: rgba($white, 20%) !important;
                caret-color: $white;
                background: #ffffff29;
            }
            .mat-checkbox-frame {
                border-color: $white;
            }
            .mat-checkbox .mat-checkbox-label {
                color: $white;
            }
        }
        .login-btn {
            background: $white;
            color: $black;
            width: 100%;
        }
    }
    // forgot pwd page
    .fp-main-content,
    .sts-login {
        .container-base-variant1 {
            padding: 80px 30px;
            text-align: center;
        }
        &.fp-mobile-main-content {
            .container-base {
                padding: 0px 25px;
            }
            .lr-content {
                margin-top: 0px;
            }
            .sts-form {
                input {
                    background: $white;
                }
                .otp-input-box{
                    width: 45px;
                    height: 45px;
                }
            }
            .timer {
                position: unset !important;
            }
            .mobile-titleimage {
                background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
                    linear-gradient(168.98deg, #dbe7f6 1.41%, rgba(255, 255, 255, 0) 98.85%);
                @include border-radius(20px);
                width: 7.5rem;
                height: 7.5rem;
                line-height: 7.5rem;
            }
        }
        .lr-rightside {
            background: transparent !important;
            .container-base-variant2 {
                padding: 0px;
            }
        }
    }
    .mat-dialog-container{
        min-width: unset;
    }
    .sts-carousel-mob{
        .owl-stage-outer{
            padding: 0px !important;
        }
    }
    .mobile-our-service{
        .owl-item{
            padding-left: 5px;
            height: 360px;
            overflow: auto;
        }
    }
    .container-base-variant2{
        padding: 0px;
    }
    .full-width-btn{
        .mat-flat-button{
            min-width: unset !important;
            padding: 0.375rem 2.25rem !important;
        }
    }
}
// LANDSCAPE
@media only screen and (max-width: 896px) and (orientation: landscape) {
    .sts-landing {
        overflow-x: hidden;
        .hero-banner {
            background-position: top;
            .banner-content {
                padding-top: 85px;
            }
            .banner-padding-base {
                padding: 85px 45px 40px 45px;
            }
            .bus-icon {
                width: 24px;
                height: 24px;
            }
        }
        .image-shadow {
            &:before {
                width: 350px;
                height: 350px;
            }
            .shadow {
                display: none;
            }
        }
        .logo {
            img {
                max-width: 100%;
            }
        }
        .landing-btn {
            &.landing-secondary-btn {
                min-width: unset !important;
            }
        }
        .topbar {
            padding: 15px 20px;
            top: 10px;
            .login-btn button {
                min-width: 5rem;
            }
        }
        .fixed-top {
            top: 0px;
            .login-btn-scrolled {
                button {
                    min-width: unset !important;
                }
            }
        }
        .banner-padding-base {
            padding: 100px 30px 50px 20px;
            text-align: center;
            .fs52-variant1 {
                @include fontsize-variant-1($font-size-32, $font-weight-semibold, $white);
                line-height: 38px;
                text-align: center;
                br {
                    display: none;
                }
            }
        }
        .horizontal-text {
            @include fontsize-variant-1($font-size-130, $font-weight-bold, rgba($white, 0.1));
        }
        .padding-base {
            padding: 70px 25px;
        }
        .vertical-text {
            display: none;
        }
        .owl-dots {
            text-align: left;
        }
        // about
        .landing-about {
            .landing-bg {
                background-image: none;
            }
        }
        // md message
        .landing-md-message {
            .padding-base {
                padding-top: 70px;
            }
            .quote-image {
                left: 0px;
            }
        }
        .landing-awards {
            .owl-dots {
                margin-top: 0px !important;
                .owl-dot {
                    span {
                        background: transparent;
                        border: 1px solid $base-blue-color;
                        width: 14px;
                        height: 14px;
                    }
                    &.active {
                        span {
                            background: $base-blue-color;
                        }
                    }
                }
            }
        }
        .landing-offers {
            .sts-carousel-offers {
                margin-top: 1rem;
                .owl-dots {
                    position: unset;
                    margin-top: 20px !important;
                }
            }
        }
        .landing-location {
            .location-inner-section address {
                height: unset;
            }
            .owl-item .carousel-item-content {
                margin-right: 0px;
            }
        }
        .mobile-footer {
            text-align: center;
        }
        #contactsection {
            background-image: none;
            .circle-bg {
                &::before {
                    display: none;
                }
            }
        }
    }
}
