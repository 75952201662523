.sts-table{
&.variant1{
    thead{
        tr{
            th{
                background: #e2f5ff;
                color: #254c6f;
                border: none;
                &:first-child{
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
                &:last-child{
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }
    tbody{
        position: relative;
        &:before{
            line-height:0.5em; content:"\200C"; display:block
        }
        tr{
            border: 1px solid #dee2e6;
            td{
                border: none;
                color: #254c6f;
                font-weight: 500;
            }
        }
    }
}
&.variant2{
    padding: 0px;
 table{
    width: 100%;
    background: transparent;
    font-family: $font-family-urbanist;
    thead{
        tr{
            th{
                background: #e2f5ff;
                color: #254c6f;
                border: none;
                z-index: 1;
                position: relative;
                font-size: 16px;
                font-weight: 800;
                &:first-child{
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
                &:last-child{
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
            th.mat-header-cell:first-of-type{
                padding-left: 15px;
                position: relative;
                left: 13px;
            }
        }
    }
    tbody{
        position: relative;
        &:before{
            line-height:0.5em; content:"\200C"; display:block
        }
        tr{
            border: 1px solid #dee2e6;
            background: #fff;
            border-right: none;
            td{
                border: none;
                color: #254c6f;
                font-weight: 500;
                z-index: 1;
                position: relative;
            }
            td.mat-cell:first-of-type{
                padding-left: 15px;
                position: relative;
                left: 13px;
                position: relative;
                &::before{
                    position: absolute;
                    left: -2px;
                    height: 100%;
                    width: 1px;
                    background: #dee2e6;
                    content: "";
                    top: 0px;
                }
            }
            td.mat-cell:last-of-type{
                    &::after{
                        position: absolute;
                        right: -2px;
                        height: 100%;
                        width: 1px;
                        background: #dee2e6;
                        content: "";
                        top: 0px;
                    }
            }
            &:nth-child(even){
 td{
    background: #f5f5f5;
 }
            }
        }
    }
 }
}
&.variant3{
    padding: 0px;
 table{
    width: 100%;
    background: transparent;
    font-family: $font-family-urbanist;
    thead{
        tr{
            th{
                background: #e2f5ff;
                color: #254c6f;
                border: none;
                z-index: 1;
                position: relative;
                font-size: 16px;
                font-weight: 800;
                &:first-child{
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
                &:last-child{
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
    tbody{
        position: relative;
        &:before{
            line-height:0.5em; content:"\200C"; display:block
        }
        tr{
            border: 1px solid #dee2e6;
            background: #fff;
            // border-right: none;
            // border-left: none;
            td{
                border: none;
                color: #254c6f;
                font-weight: 500;
                z-index: 1;
                position: relative;
            }
            &:nth-child(even){
 td{
    background: #f5f5f5;
 }
            }
        }
    }
 }
}
}