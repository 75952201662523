.mat-flat-button{
    font-family: $font-family-urbanist;
    @include border-radius(30px !important);
    min-width: 10rem !important;
    @media(max-width: 992px) {
        min-width: 8rem !important;
    }
    border: none;
    height: 2.5rem!important;
    line-height: 1.125rem !important;
    padding: 0.375rem 1.25rem !important;
    &.width-md{
        min-width: 8rem !important;
    }
    &.width-sm{
        min-width: 5.375rem !important;
        height: 1.875rem !important;
        &.p-0{
            padding: 0px !important;
        }
    }
    &.mat-btn-primary{
        background: $base-blue-color;
        border: 1px solid $base-blue-color;
        background-color: $base-blue-color !important;
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $white !important);
        &.in-active{
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
        }
        &.mat-button-disabled{
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
        }
    }
    &.mat-btn-secondary{
        border: 1px solid $base-blue-color;
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-blue-color);
        &.mat-button-disabled{
            opacity: 0.5;
            pointer-events: none;
            cursor: default;
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
    &.mat-btn-teritary{
        min-width: 114px !important;
        background: $white;
        width: 114px;
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-black-color);
        padding: 0px !important;
    }
    &.mat-btn-variant4{
        border: 1px solid $base-blue-color;
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-blue-color);
        // background: rgba(#0977C5, 6%);
    }
    &.mat-btn-variant5{
        background: #fff;
        border: 1px solid $base-black-color;
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-black-color);
    }
    &.uae-btn{
        width: 22.5rem;
    }
}

.uaebtn-inactive{
    opacity: 0.3;
}
.red-btn{
    color: #F7537A !important;
    border: 1px solid #F7537A !important;
}