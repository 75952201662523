.sts-slider{
    .mat-slider-horizontal {
        height: 48px;
        min-width: 100%;
        &[aria-valuetext="100k"]{
            .mat-slider-thumb-label{
                right: -15px !important;
            }
        }
        .mat-slider-track-wrapper{
            height: 10px;
            @include border-radius(100px);
            .mat-slider-track-background{
                height: 10px;
                background: rgba(#0977C5, 10%);
            }
            .mat-slider-track-fill{
                height: 10px;
                background: linear-gradient(180deg, #0977C5 0%, #33A9E0 91.87%);
                @include border-radius(100px);
            }
        }
        .mat-slider-ticks-container{
            display: none;
        }
        .mat-slider-thumb-container{
            .mat-slider-thumb{
                display: none;
            }
            // .mat-slider-thumb{
            //     background: url(../../assets/images/ranger-bg.png);
            //     transform: unset;
            //     background-position: center center;
            //     background-size: contain;
            //     border: none;
            //     background-color: transparent;
            //     width: 48px;
            //     height: 48px;
            //     top: -21px;
            //     border-radius: 0px;
            //     background-repeat: no-repeat;
            // }
            .mat-slider-thumb-label{
                background: url(../../assets/images/ranger-bg.png);
                transform: unset;
                border-radius: 0px;
                background-size: contain;
                width: 48px;
                height: 48px;
                background-repeat: no-repeat;
                z-index: 3;
                right: -22px;
                top: -22px;
                .mat-slider-thumb-label-text{
                    display: none;
                }
            }
        }
    }
}