.sts-accordion{
    margin-top: 2rem;
    .mat-accordion{
        margin-bottom: 1.5rem;display: block;
        .mat-expansion-panel{
            @include border-radius(20px  !important);
            box-shadow: 0px 4px 45px 0px #1A2A610F;
            margin-bottom: 1.5rem;
            .mat-expansion-panel-header{
                font-family: $font-family-urbanist;
               background: $white !important;
                padding: 1.2rem;
                height: unset;
            }
            .mat-expansion-panel-content{
                font-family: $font-family-urbanist;
            }
        }
        &.rm-body-padding{
            mat-panel-title{
                cursor: default;
            }
            .mat-expansion-panel-body{
                padding: 0px;
            }
        }
        &.rm-flex-mat-content{
            .mat-content{
                display: unset;
            }
        }
    }
    &.variant-1 {
        @include border-radius(20px !important);
        box-shadow: 0px 4px 45px 0px #1A2A610F;
        margin-bottom: 1.5rem;
        background: $white !important;
        padding: 1.2rem;
        .mat-accordion {
            display: block;
            margin-bottom: 0px;
            .mat-expansion-panel {
                box-shadow: none;
                margin-bottom: 0px;
                border-radius: 0px !important;
                .mat-expansion-panel-header {
                    font-family: $font-family-urbanist;
                    height: unset;
                    padding: 0px;
                    &:hover {
                        background: transparent;
                    }
                    &[aria-expanded="false"]{
                        border-bottom: 1px solid #dee2e6;
                        margin-bottom: 10px;
                        .mat-expansion-indicator{
                            margin-top: 0px
                        }
                    }
                }
                .mat-expansion-panel-content {
                    font-family: $font-family-urbanist;
                }
            }
            .mat-expansion-panel-body {
                padding: 0px;
            }
            &.rm-body-padding {
                mat-panel-title {
                    cursor: default;
                }
                .mat-expansion-panel-body {
                    padding: 0px;
                }
            }
            &.rm-flex-mat-content {
                .mat-content {
                    display: unset;
                }
            }
        }
        &.indicator-top {
            .mat-expansion-indicator {
                margin-top: 5px;
                margin-right: 10px
            }
        }
    }
}
// accordion box shadow
.accordion-box-shadow{
    box-shadow: 0px 4px 45px 0px #1A2A610F;
}
// slide toggle
.mat-slide-toggle{
    height: unset !important;
    .mat-slide-toggle-bar{
        width: 55px !important;
        height: 26px !important;
        @include border-radius(40px !important);
        background: rgba(#0977C5, 10%) !important
    }
    .mat-slide-toggle-thumb-container{
        top: 3px;
        left: 3px;
    }
    .mat-slide-toggle-thumb{
        width: 20px;
        height: 20px;
        background-color: $white !important;
    }
    .mat-checked .mat-slide-toggle-thumb{
        background-color: $white !important;
    }
    &.mat-checked .mat-slide-toggle-bar{
        background-color: $base-blue-color !important;
    }
    .mat-slide-toggle-label{
        height: unset;
        line-height: unset;
    }
    .mat-ripple{
        display: none;
    }
    &.mat-checked .mat-slide-toggle-thumb-container{
        left: 16px;
    }
}
