.sts-landing {
    .qr-code{
        background: $white;
        @include border-radius(8px);
    }
    .hero-banner {
        background-image: url("../../assets/images/landing-page/hero-banner.png");
        background-size: cover;
        background-position: center center;
        color: $white;
        .banner-content {
            // padding-top: 30px;
        }
        .banner-padding-base {
            padding: 85px 85px 40px 85px;
        }
    }
    .topbar {
        background: transparent;
        padding: 0px 45px;
        padding-top: 2.5rem;
        ul {
            li {
                a {
                    @include fontsize-variant-1($font-size-18, $font-weight-semilight, rgba($white, 0.6));
                    &.current-section {
                        @include fontsize-variant-1($font-size-18, $font-weight-bold, $white);
                }
                }
            }
            .active {
                a {
                    @include fontsize-variant-1($font-size-18, $font-weight-bold, $white);
                }
            }
        }
        .login-btn {
            button {
                @include fontsize-variant-1($font-size-16, $font-weight-medium, $white);
                background: rgba($white, 0.06);
                min-width: 7.5rem;
                height: 2.5rem;
                @include border-radius(100px);
                border: none;
                &:first-child{
                  background: $base-blue-color;
                }
                &.login-btn-primary {
                  background: $base-blue-color;
                }
            }
        }
    }
    .landing-btn {
        font-family: $font-family-urbanist;
        @include border-radius(30px !important);
        min-width: 13rem !important;
        border: none;
        height: 3.063rem !important;
        line-height: 1.125rem !important;
        padding: 0.375rem 1.25rem !important;
        &.landing-primary-btn {
            background: $base-blue-color;
            @include fontsize-variant-1($font-size-16, $font-weight-semibold, $white);
        }
        &.landing-secondary-btn {
            background: $white;
            @include fontsize-variant-1($font-size-16, $font-weight-semibold, $black);
        }
    }
    // padding-base
    .padding-base {
        padding: 85px;
    }
    // landing-about
    .landing-about {
        background: #0f1531;
        .landing-bg {
            // background: url(../../assets/images/landing-page/about-right.svg);
            // background-repeat: no-repeat;
            // background-position: calc(100% - -140px) center;
            // position: relative;
            // z-index: 1;
        }
        .right-image{
            position: relative;
            img{
                background: transparent;
                border-radius: 20px;
                z-index: 1;
                position: relative;
            }
        }
    }
    // shadow image with animate colors
    .image-shadow{
        position: relative;
        display: inline-block;
        &:before{
            position: absolute;
            content: "";
            top:18px;
            left:-18px;
            background: linear-gradient(312deg, #00aeff, #0d2396);
            background-size: 400% 400%;
            -webkit-animation: AnimationName 12s ease infinite;
            -moz-animation: AnimationName 12s ease infinite;
            animation: AnimationName 12s ease infinite;
            width: 100%;
    height: 100%;
            border-radius: 20px;
            z-index: 1;
        }
        @-webkit-keyframes AnimationName {
            0%{background-position:17% 0%}
            50%{background-position:84% 100%}
            100%{background-position:17% 0%}
        }
        @-moz-keyframes AnimationName {
            0%{background-position:17% 0%}
            50%{background-position:84% 100%}
            100%{background-position:17% 0%}
        }
        @keyframes AnimationName {
            0%{background-position:17% 0%}
            50%{background-position:84% 100%}
            100%{background-position:17% 0%}
        }
    }
    .shadow{
        position: relative;
        &:after {
            position: absolute;
            content: "";
            box-shadow: 0px 0px 100px 100px rgba(#0092F9, 0.22);
            width: 386px;
            height: 386px;
            left: -9px;
            top: -165px;
            border-radius: 100%;
            background: transparent;
            z-index: 0;
        }
        @media screen and (max-width: 992px){
            display: none;
        }
    }
    // vertical-text
    .vertical-text {
        @extend .fs130-variant1;
        transform: rotate(-90deg);
        position: absolute;
        bottom: 90px;
        right: -25px;
        opacity: 50%;
        letter-spacing: 2px;
        &.vertical-text-service {
            right: 1px;
            bottom: 165px;
            @extend .fs100-variant1;
        }
    }
    //horizontal text
    .horizontal-text {
        @extend .fs150-variant1;
        position: absolute;
        top: -43px;
        left: 9px;
        letter-spacing: 4px;
        opacity: 50%;
    }
    .fixed-top {
        background: white;
        padding-top: 0px;
        box-shadow: 0 6px 8px -2px #8080801c;
        ul {
            li {
                a {
                    @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($base-black-color, 0.6));
                    &.current-section {
                        @include fontsize-variant-1($font-size-16, $font-weight-bold, $base-blue-color);
                }
                }
            }
        }
        .login-btn {
            button {
                @include fontsize-variant-1($font-size-16, $font-weight-medium, $base-blue-color);
                background: rgba(9, 119, 197, 0.1019607843);
                min-width: 7.5rem;
                height: 2.5rem;
                @include border-radius(100px);
                border: none;
            }
        }
        .login-btn-scrolled{
          display: flex;
          justify-content: space-between;
          align-items: center;
            button{
                min-width: 7.5rem !important;
            }
        }
    }
    // landing-md-message
    .landing-md-message {
        background-image: url(../../assets/images/landing-page/pattern.png);
        background-color: #fff;
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 193px;
        .padding-base{
            padding-top: 140px;
        }
        .quote-image {
            top: -40px;
            left: -40px;
        }
    }
    // landing-footer
    .landing-footer {
        background-color: #0d0d2c;
        background-image: url("../../assets/images/landing-page/footer-circle.png");
        background-size: 700px 690px;
        background-position: right bottom;
        background-repeat: no-repeat;
        @media (max-width: 767px){
            .padding-base{
                padding-top: 45px;
            }
        }
        .sublinks {
            ul {
                margin: 0px;
                li {
                    a {
                        @include fontsize-variant-1($font-size-16, $font-weight-semilight, rgba($white, 0.8));
                        margin: 15px 0px;
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }
        .subscribe {
            input {
                width: 100%;
                padding-right: 9.5rem;
                height: 50px;
                padding-left: 25px;
                @include border-radius(100px);
                border: 1px solid rgba($white, 0.2) !important;
                caret-color: $white;
                color: $white;
                &::-webkit-input-placeholder {
                    color: rgba($white, 0.6);
                }
            }
            button{
                background-color: $base-blue-color;
                width: 8.25rem;
                height: 3.125rem;
                @include border-radius(100px);
                color: $white;
                border: none;
                position: absolute;
                right: 0px;
                bottom: 0px;
                @media (max-width: 767px){
                    width: 9.25rem;
                }
            }
            mat-form-field {
                position: relative;
                .mat-flat-button {
                    position: absolute;
                    right: 1px;
                    top: 0px;
                }
                .mat-btn-primary {
                    height: 43px !important;
                }
                .mat-form-field-label {
                    color: #fff9;
                    line-height: 50px;
                    padding-left: 25px;
                }
            }
        }
        .footer-strip {
            padding: 25px 0px;
            @include fontsize-variant-1($font-size-16, $font-weight-semilight, rgba($white, 0.6));
            position: relative;
            margin-top: 20px;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) -4.49%, rgba(217, 217, 217, 0) -4.49%, rgba(237, 237, 237, 0.312) 48.45%, rgba(237, 237, 237, 0) 100%);
                content: "";
            }
        }
    }
    // landing-support
    .landing-support {
        background-image: url(../../assets/images/landing-page/pattern.png);
        background-color: #fff;
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 193px;
        .circle-bg {
            position: relative;
            &:before {
                background: url("../../assets/images/landing-page/footer-circle.png");
                background-size: cover;
                background-position: right bottom;
                background-repeat: no-repeat;
                position: absolute;
                bottom: 0px;
                content: "";
                right: 0px;
                height: 200px;
                width: 300px;
                opacity: 0.3;
            }
        }
        .horizontal-text {
            color: rgba(#050518, 8%);
        }
        .attachment {
            .mat-form-field-suffix {
                position: absolute;
                top: 35px;
                right: 10px;
                .mat-icon {
                    transform: rotate(45deg);
                }
            }
            .attachment-icon{
                top: 31px;
                position: absolute;
                right: 0.8px;
                background: #fff;
                padding: 10px;
            }
        }
        .mat-form-field-invalid{
            .mat-form-field-suffix{
                bottom: 7px;
            }
        }
    }
    // landing-location
    .landing-location {
        background: url(../../assets/images/landing-page/location-blue-bg.svg);
        background-size: cover;
        &:before {
            position: absolute;
            background: url(../../assets/images/landing-page/map-image.png);
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            content: "";
        }
        .location-inner-section {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.06) 100%);
            @include border-radius(30px);
            padding: 20px;
            backdrop-filter: blur(10px);
            height: 100%;
            // .address-height{
            //     height: 100px;
            // }
            .location-image {
                width: 100%;
                border-radius: 16px;
            }
            .contact-detail {
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
           .owl-item{
                .carousel-item-content{
                    margin-right: 2rem;
                }
            }
            .owl-stage{
                padding-left: 0px !important
            }

    }
    // owl nav and dots
    .owl-dots{
        margin-top: 3rem !important;
        @media screen and (max-width: 992px){
            margin-top: 1rem !important
        }
    }
    .owl-nav{
        margin-top: 3rem !important;
        @media screen and (max-width: 767px){
            margin-top: 2rem !important
        }
    }
    // landing-awards
    .landing-awards {
        background-image: url(../../assets/images/landing-page/pattern2.png);
        background-color: #fff;
        background-position: right top;
        background-repeat: no-repeat;
        background-size: 170px;
        position: relative;
        overflow: hidden;
        &:after {
            position: absolute;
            top: 0;
            right: -16px;
            background: url(../../assets/images/landing-page/blue-bg-transparent.png);
            content: "";
            width: 500px;
            height: 300px;
        }
        .mat-tab-body-wrapper{
            display: unset;
        }
    }
    // landing-news
    .landing-news {
        background: linear-gradient(242.48deg, #0F2F5E 8.11%, #0F0F21 92.19%);
        overflow: hidden;
        .padding-base {
            padding-right: 0.125rem;
        }
    }
    // carousel owl slider
    .sts-carousel-award {
        margin-top: 1rem;
        .owl-nav {
            text-align: left;
            margin-top: 0px;
            .owl-prev,
            .owl-next {
                background: transparent;
                margin: 0px;
                &:hover {
                    background: transparent;
                }
            }
        }
        .owl-stage-outer {
            padding: 40px 0px;
            @media(max-width: 767px){
                padding: 0px;;
            }
        }
        .left-nav,
        .right-nav {
            background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
                linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
            width: 56px;
            height: 56px;
            @include border-radius(50px);
            line-height: 56px;
            text-align: center;
        }
        .left-nav {
            transform: rotate(180deg);
            img {
                transform: rotate(-180deg);
            }
        }
        .carousel-item-content {
            // background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
            //     linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
            @include border-radius(30px);
            padding: 25px;
            font-family: $font-family-urbanist;
            margin-right: 2rem;
            box-shadow: 20px 20px 50px 0px #193D830D;
            // background: linear-gradient(179.53deg, rgba(234, 255, 254, 0.5) 0%, #c9d9f180 97.46%), linear-gradient(168.98deg, #c9d9f170 1.41%, rgba(255, 255, 255, 0) 98.85%);
            background: #E0F1FD;
            .award-image {
                width: 80px;
                height: 80px;
                background: $white;
                @include border-radius(16px);
                padding: 5px;
                display: flex;
                align-items: center;
                img {
                    width: 100%;
                    margin: auto;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    // news carousel slider
    .sts-carousel-news {
        margin-top: 3rem;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            background-image: url("../../assets/images/landing-page/circle-pattern-news.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            right: -165px;
            top: 0;
            width: 680px;
            height: 650px;
        }
        .carousel-item-content {
            @include border-radius(30px);
            padding: 20px;
            font-family: $font-family-urbanist;
            margin-right: 2rem;
            background: rgba($white, 6%);
            backdrop-filter: blur(10px);
            .desc{
                // height: 50px;
            }
        }
        .news-btn {
            min-width: 6.75rem !important;
            color: #fff;
            background: rgba(255, 255, 255, 0.06);
        }
        .news-image{
            @include border-radius(16px)
        }
    }
    //black owl nav styles
    .owl-nav-style1{
        .owl-nav {
            text-align: left;
            .owl-prev,
            .owl-next {
                background: transparent;
                margin: 0px;
                &:hover {
                    background: transparent;
                }
            }
        }
        .left-nav,
        .right-nav {
            background: rgba($white, 6%);
            width: 56px;
            height: 56px;
            @include border-radius(50px);
            line-height: 56px;
            text-align: center;
        }
        .left-nav {
            transform: rotate(180deg);
            img {
                transform: rotate(-180deg);
            }
        }
    }
    // landing-offers
    .landing-offers {
        background: #101E45;
        margin-bottom: -1px;
        .padding-base {
            padding-right: 0.125rem;
        }
        .sts-carousel-offers {
            margin-top: 3rem;
            position: relative;
            .carousel-item-content {
                font-family: $font-family-urbanist;
                width: unset;
                height: unset;
                img{
                    max-width: 100%;
                    width: unset;
                }
            }
            // .owl-nav {
            //     position: absolute;
            //     bottom: -90px;
            //     left: 0px;
            // }
        }
    }
    // owl-dots ui
    .owl-dots {
        .owl-dot {
            span {
                background: transparent;
                border: 1px solid $white;
                width: 14px;
                height: 14px;
                @media screen and (max-width: 767px){
                    width: 12px;
                    height: 12px;
                    margin: 5px;
                }
            }
            &.active {
                span {
                    background: $white;
                }
            }
        }
    }
    // landing-service
    .landing-service {
        background: #006DD2;
        position: relative;
        .sts-carousel-service {
            padding-bottom: 4rem;
            .owl-dots {
                text-align: left;
                .owl-dot {
                    span {
                        background: transparent;
                        border: 1px solid $white;
                        width: 14px;
                        height: 14px;
                        @media screen and (max-width: 767px){
                            width: 12px;
                            height: 12px;
                            margin: 5px;
                        }
                    }
                    &.active {
                        span {
                            background: $white;
                        }
                    }
                }
            }
        }
        .tab-content{
            padding-bottom: 70px;
            .tab-pane{
                margin: 0px;
            }
        }
        .nav-tabs{
            align-items: center;
                justify-content: center;
                background: linear-gradient(242.48deg, #0F2F5E 8.11%, #0F0F21 92.19%);
                border-radius: 100px;
                padding: 10px;
                justify-content: space-around;
                border: none;
                position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    width: 85%;
    margin: auto;
    z-index: 1;
                .nav-item{
                    position: relative;
                    &:focus-visible{
                        outline: none;
                    }
                    .nav-link{
                        text-align: center;
                        border: none;
                        position: relative;
                        // width: 180px;
                        &.active{
                            background: transparent;
                            border: none;
                            .tab-title {
                                @include fontsize-variant-1($font-size-16, $font-weight-medium, $blue-variant3);
                            }
                            &:before {
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 17px;
                                border-color: #0f2850 transparent transparent #0f2850;
                                border-radius: 10px 0px 0px 0px;
                                position: absolute;
                                top: -27px;
                                left: 43%;
                                right: 0;
                                content: "";
                                transform: rotate(45deg);
                            }
                        }
                    }
                    &:after {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(237, 237, 237, 0.39) 46.97%, rgba(217, 217, 217, 0) 100%);
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        content: "";
                        top: 0;
                        right: -64px;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
        }
    }

    .owl-stage{
        padding-left: 0px !important
    }
    .social-icons {
        .by-hover {
            display: none;
        }
        a:hover{
                .by-hover {
                    display: block;
                }
                .white-icon{
                    display: none;
                }
        }
    }
}
// carousel custom width if one item available in carousel
.carouselCustomWidth{
    width: 440px !important;
    .owl-stage{
        width: 100% !important;
        padding-right: 0px !important;
    }
    .owl-item{
        width: 100% !important
    }
}



.owl-carousel {
	.owl-stage {
		display: flex;
	}
	.owl-item {
		display: flex;
        padding-right: 2rem;
        @media screen and (max-width: 767px){
            padding-right: 1.2rem;
        }
	}
    .carousel-item-content{
        height: 100%;
        width: 100%;
    }
}

