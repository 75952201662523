// Base Typography
$font-family-urbanist: "Urbanist",sans-serif !default;
$font-family-dmsans: 'DM Sans', sans-serif !default;
$font-family-roboto: "Roboto", sans-serif !default;
// colors
$body-bg: #fff !default;
$white: #ffffff !default;
$black: #000000 !default;
$base-black-color: #050518;
$base-blue-color: #0977C5;
$blue-variant1: rgba(#0056A6,0.04);
// $blue-variant2: #0977c51a;
$blue-variant2: rgba(#0977C5, 0.06);
$blue-variant3: #DFECFE;
$blue-variant4: #E0F1FD;
//black variants
$black-variant1: #111111;
$black-variant2: #050518;
// border radius
$border-radius6: 6px;
$border-radius8: 8px;
$border-radius12: 12px;
$border-radius16: 16px;
$border-radius20: 20px;
$border-radius24: 24px;
$font-weight-thin: 100 !default;
$font-weight-light: 200 !default;
$font-weight-semilight: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-size-8: 0.5rem;
$font-size-9: 0.563rem;
$font-size-10: 0.625rem;
$font-size-11: 0.6875rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-13: 0.813rem;
$font-size-15: 0.938rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-21: 1.313rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-40: 2.5rem;
$font-size-42: 2.625rem;
$font-size-45: 2.813rem;
$font-size-48: 3rem;
$font-size-52: 3.25rem;
$font-size-54: 3.375rem;
$font-size-100: 6.25rem;
$font-size-130: 8.125rem;
$font-size-150: 9.375rem;
// Default button colors
$btn-primary: #1A2C6A;
$btn-secondary: #3B4979;
$btn-hover: #1A2C6A;