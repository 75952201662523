.sts-datepicker{
    .mat-form-field-flex{
        position: relative;
        .mat-form-field-suffix{
            position: absolute;
            top: 42px;
            right: 10px;
        }
        .mat-icon-button{
            background: url("../../assets/images/calendar-gray.svg");
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border-radius: 0px;
            height: 24px;
            width: 24px;
            span{
                display: none;
            }
        }
        .mat-form-field-label {
            // display: block ;
        }
    }
}
.sts-timepicker{
    .form-label{
        font-size: 1rem;
        font-weight: 400;
        color: #050518;
        margin-bottom: 10px;
        text-align: left;
        display: block;
    }
    ngx-timepicker-field{
        border: none;
        padding: 0px;
    }
    .ngx-timepicker{
        border-bottom: none !important;
        gap: 10px;
        input{
            height: 50px !important;
            width: 65px;
        }
        .ngx-timepicker__time-colon{
            margin-left: 0.5rem;
        }
        .period-selector__button--active{
            color: $base-blue-color !important;
        }
        .ngx-material-timepicker-toggle{
            &:focus{
                background-color: transparent !important;
            }
        }
        .period-selector__button{
            height: unset;
            line-height: unset;
        }

    }
    .ngx-timepicker-control__arrows {
        position: absolute;
        right: 0px !important;
        top: 7px !important;
    }
    .ngx-timepicker-control{
        &:after{
            display: none;
        }
        padding: 0px !important;
    }
    .ngx-timepicker-control,.ngx-timepicker__control--first{
        height: 50px !important;
    }
    .period-control__button{
        margin-left: 0.2rem;
        &:focus{
            &::after{
                display: none;
            }
        }
    }
    .timepicker-button{
        text-transform: capitalize !important;
    }
}
//common css for timepicker popup
ngx-material-timepicker-container {
    .timepicker-period{
        margin-top: 10px;
    }
    .timepicker-period__btn{
        margin-top: 0px !important;
    }
    .clock-face__number>span.active,
    .timepicker__header,
    .clock-face__clock-hand {
        background-color: $base-blue-color !important;
    }
    .clock-face__clock-hand {
        &:after {
            left: -3.2px !important;
        }
    }
    .clock-face__clock-hand_minute{
        &:before{
            left: calc(50% - 7px) !important;
            border: 4px solid $base-blue-color !important;
        }
    }
    .timepicker-backdrop-overlay {
        background: rgba(0, 0, 0, 0.3);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
    }
    .timepicker {
        box-shadow: none !important;
        width: 350px !important;
        .timepicker__header {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }
        .timepicker__actions {
            justify-content: center !important;
            gap: 10px !important;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            .timepicker-button {

                background: #0977C5;
                border: 1px solid #0977C5;
                background-color: #0977C5 !important;
                font-size: 0.875rem;
                font-weight: 600;
                color: #ffffff !important;
                font-family: "Urbanist", sans-serif;
                border-radius: 30px !important;
                min-width: 8rem !important;
                border: none;
                height: 2.5rem !important;
                line-height: 1.125rem !important;
                padding: 0.375rem 1.25rem !important;
                span{
                    text-transform: capitalize !important;
                    font-family: "Urbanist", sans-serif !important;
                }
            }
            div {
                &:first-child {
                    .timepicker-button {
                        background-color: transparent !important;
                        border: 1px solid $base-blue-color !important;
                        color: #0977C5 !important;
                    }
                }
            }
        }
        .timepicker-dial__container {
            justify-content: center !important;
        }
        .timepicker-dial {
            .timepicker-dial__time {
                align-items: center;
            }
            .timepicker-dial__control {
                font-size: 34px;
                text-align: center;
            }
            .timepicker-period__btn {
                font-size: 14px;
                font-family: "Urbanist", sans-serif !important;
            }
            .timepicker-period__btn {
                margin-top: 10px;
            }
        }
    }
}