// Custom Container
.container-custom {
    padding: 0 45px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1100px) {
        padding: 0 15px;
    }
    .mobile-nav {
      ul {
        @media(max-width: 767px){
          padding-left: .25rem !important;
        }
      }
    }
}


// Header Styles
.topbar {
    height: 72px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: $white;

    ul {
        li {
            margin-right: 1rem;
            @media(max-width: 767px) {
              margin-right: 0px;
            }
            a {
                @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($black-variant2, 80%));
            }
            .more-menu{
                @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($black-variant2, 80%));
            }

            &.active {
                a {
                    @include fontsize-variant-1($font-size-16, $font-weight-semibold, $base-blue-color);
                }
            }
            .user-info{
                background: rgba($base-blue-color, 10%);
                @include border-radius(30px);
                height: 2.75rem;
                padding: 10px 14px 10px 2px;
            }
        }
    }

    .notification-badge {
        background: #F7537A;
        color: $white;
        width: 26px;
        height: 18px;
        display: flex;
        padding: 2px;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -0.4rem;
        top: 0;
        @include border-radius($border-radius12);
        border: 2px solid $white;
        @include fontsize-variant-1($font-size-12, $font-weight-normal, $white);
        @media screen and (max-width: 992px){
            right: -0.7rem;
            top: -8px;
        }
    }
}

.sts-main-content {
    padding-top: 90px;
    // padding-top: 75px;
}

.prelogin-topbar {
    z-index: 1000;
    position: relative;
}

// Mobile responsive menu
@media screen and (max-width:992px) {
    .navbar-toggler {
        &:focus {
            box-shadow: none;
        }
    }
    // .mobile-menu {
    //     position: absolute;
    //     width: 100%;
    //     top: 43px;
    //     left: 0px;
    //     right: 0px;
    //     background: $base-blue-color;
    // }

    .topbar {
        height: unset;
        padding-top: 10px;

        ul li {
            width: 100%;
            text-align: left;
            padding: 0.4rem 1.5rem;
            color: $white;
            .fs14-variant4{
                color: #fff;
            }

            a {
                color: $white;
                padding: 0px;
            }
        }
        .menu{
            // background: $base-blue-color;
            margin-top: 5px;
            .navbar{
                padding-left: 1rem;
                border-bottom: 1px solid #fff;
            }
            .navbar-toggler{
                padding: 0px;
                border:none
            }
        }
    }
}
// sidebar css
.sidebar {
    min-height: calc(100vh - (72px + 54px + 60px));
    position: sticky;
    position: -webkit-sticky;
    top: 10.15rem;
    height: 100%;
    background: $white;
    box-shadow: 0px 4px 45px 0px #1A2A610F;
    @include border-radius(20px);
    padding: 15px;
    width: 109px;
    min-width: 109px;
    ul {
        min-height: calc(100% - 75px);
        overflow-y: auto;
        height: 500px;
        overflow-x: hidden;
        @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 20px;
            border: 6px solid transparent;
          }
        li {
            text-align: center;
            padding-top: 12px;
            &:last-child{
                .gradient-border3{
                    display: none;
                }
            }
        }
    }
    .icon {
        background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
            linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
        width: 56px;
        height: 56px;
        @include border-radius(100px);
        text-align: center;
        line-height: 56px;
        margin: auto;
    }
}

.quick-links{
    background: $white;
    box-shadow: 0px 4px 45px 0px #1A2A610F;
    @include border-radius(20px);
    padding: 15px 1px;
    width: 76px;
    // min-height: calc(100vh - 245px);
    min-height: 100%;
    top: 156px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 109px;
    min-width: 109px;
    gap: 10px;
    ul {
        // min-height: calc(100% - 75px);
        // overflow-y: auto;
        // height: 500px;
        // overflow-x: hidden;
        // @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        // &::-webkit-scrollbar-thumb {
        //     background: #fff;
        //     border-radius: 20px;
        //     border: 6px solid transparent;
        //   }
        li {
            text-align: center;
            padding-top: 12px;
            &:last-child{
                .gradient-border3{
                    display: none;
                }
            }
        }
    }
    .icon {
        background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
            linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
        width: 45px;
        height: 45px;
        @include border-radius(100px);
        text-align: center;
        line-height: 45px;
        margin: auto;
        img{
            width: 25px;
            height: 25px;
        }
    }
}
.col-quick-link{
    padding-left: 15px;
    border-radius: 12px;
    height: 100%;
    z-index: 1;
    position: sticky;
    top: 8.75rem;
}
