.sts-login {
    height: 100%;
     .login-left {
        background: url("../../assets/images/login-left-bg.png?v=1");
        background-size: cover;
        height: 100%;
        background-position: center center;
    }
     .login-right {
        background: #ffffffbd url("../../assets/images/login-right-bg.png");
        background-size: cover;
        height: 100%;
        background-position: center center;
    }
     .login-btn {
        background: $base-blue-color;
        @include fontsize-variant-1($font-size-16, $font-weight-medium, $white);
        @include border-radius(30px);
        padding: 0.7rem 4.5rem;
        border: none;
    }
}
// forgot password styles
.fp-main-content{
    padding-top: 80px;
}
.lr-content {
    margin-top: 1rem;
    .lr-inner-content{
        min-height: calc(100vh - 200px);
    }
    .lr-leftside {
        background: $blue-variant1;
        @include border-radius(24px 0px 0px 24px)
    }
    .lr-rightside {
        @include border-radius(0px 24px 24px 0px)
    }
}