[dir=rtl] th.mat-header-cell:last-of-type:not(:only-of-type), [dir=rtl] td.mat-cell:last-of-type:not(:only-of-type), [dir=rtl] td.mat-footer-cell:last-of-type:not(:only-of-type){
    padding-right: 24px !important;
}
body[dir="rtl"] {
    text-align: right;
    direction: rtl;
    ul{
        padding: 0px;
    }
    .sts-carousel-offers{
        .owl-stage{
            .owl-item{
                margin-left: 20px;
                margin-right: 0px !important;
            }
        }
    }
    .home-school-section .school::after{
        width: 85% !important;
    }
    .home-school-section .home::before{
        width: 88% !important;
    }
    .home-school-section .school::after{
        right: -44px;
    }
    .home-school-section .home::before{
        right: 44px !important;
    }
    // .date-picker-v1 .mat-icon-button{
    //     position: absolute;
    //     left: 18px;
    // }
    .sts-form{
        .mat-form-field-label-wrapper {
            mat-label{
                padding-right: 20px;
            }
        }
    }
    .sts-landing .shadow:after{
        right: 0px;
    }
    .me-md-3 {
        margin-left: 1rem !important;
        margin-right: 0px !important;
    }
    .sts-landing .landing-service .nav-tabs .nav-item:first-child::after{
        visibility: hidden;
    }
    .sts-landing .landing-news .padding-base{
        padding-left: 0.125rem;
    }
    .sts-landing .landing-news .fs42-variant1,.fs42-variant1{
        margin-right: 4rem;
    }
    .sts-landing .landing-support .attachment .attachment-icon{
        right: unset;
    left: 0.8px;
    }
    #contactsection .offset-md-3{
        margin-right: 25%;
        @media(max-width: 767px) {
          margin-right: auto !important;
        }
    }
    .sts-landing .landing-footer .subscribe button{
        left: 0px;
    right: unset;
    }
    .sts-tab.tab-variant2 .mat-tab-labels .mat-tab-label .mat-label-text{
        padding-left: 20px;
        padding-right: unset;
    }
    .sts-form input[type="password"]{
        padding-right: 70px;
    }
    .blinking-text .me-1{
        margin-left: 15px;
    }
    .sts-datepicker .mat-form-field-flex .mat-form-field-suffix{
        left: 10px;
    right: unset;
    }
    .date-picker-v1 .mat-datepicker-toggle{
        left: 15px;
    right: unset !important;
    }
    .sts-form .mat-form-field .mat-input-element{
        padding-right: 15px;
    }
    .mat-radio-label-content {
        padding-right: 0px;
        padding-left: 8px;
    }
    .mat-radio-container{
        margin-left: 8px;
    }
    .pdf-btn{
        right: 92%;
        width: unset;
    }
    .me-1{
        margin-left: .25rem !important;
        margin-right: 0px !important;
    }
    .me-2 {
        margin-left: .5rem !important;
        margin-right: 0px !important;
    }
    .me-3 {
      margin-left: 1rem !important;
      margin-right: 0px !important;
  }
    .attachment-icon{
        left: 3px !important;
        right: unset !important;
    }
    .me-4 {
        margin-left: 1.5rem !important;
        margin-right: 0px !important;
    }
    .ms-1 {
      margin-right: .25rem !important;
      margin-left: 0px !important;
    }
    .ms-2 {
      margin-right: .5rem !important;
      margin-left: 0px !important;
    }
    .ms-3 {
      margin-right: 1rem !important;
      margin-left: 0px !important;
    }
    .ms-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important;
    }
    .ms-5 {
      margin-right: 3rem !important;
      margin-left: 0 !important;
    }
    .mat-form-field-infix{
        width: 100%;
    }
    .sts-landing .owl-stage{
        padding-right: 0px !important;
        padding-left: unset !important;
    }
    .show-hide-btn {
        left: 10px;
        right: unset !important;
    }
    .sts-form .form-control.is-valid{
        background-position: left calc(0.375em + 0.1875rem) center;
    }
    .phonenumber{
        direction: ltr;
    }
    .pick-drop-time{
        direction: ltr;
    }
    .progress-info{
        margin-right: unset;
        margin-left: unset;
    }
    .blinking-text{
        direction: ltr;
    }
    .breadcrumb li:not(:first-child){
        // transform: scaleX(-1);
    }
    .sts-form .form-control.is-invalid{
        background-position: left 13px center;
    }
    .sts-tab.tab-variant2 .mat-tab-list .mat-label-text:before{
        right: calc(100% - 0px);
    }
    .send-otp-btn{
      left: 0px !important;
      right: unset !important;
      @media(max-width: 767px){
        font-size: 75% !important;
        div.bg-white {
          padding: 0.25rem !important;
        }
      }
    }
    .verified-badge{
        left: 0px !important;
        right: unset !important;
    }
    .rtl-text-start{
        text-align: left !important;
    }
    .landing-page-translation-button .mat-select-arrow{
        right: -12px;
    }
    .mat-slide-toggle{
        direction: ltr;
    }

    .mat-slider-horizontal .mat-slider-track-fill{
        transform-origin: 0% 0%;
    }
    .rtl-payment-input{
        width: 200px;
    }

    .feeWidgetArrow {
        transform: scaleX(-1);
    }
    .chatbot-float {
        position: fixed;
        bottom: 10px;
        left: 10px;
        right: auto;
        z-index: 1000;
    }
    .language-panel .mat-option.mat-selected:not(.mat-option-multiple):after{
        left: 15px;
    }
    .language-panel .mat-option.lang-eng {
      text-align: left;
    }
    .sts-form .mat-form-field .mat-form-field-label{
        span{
            padding-right: 15px;
        }
    }
    .list-dot-sm{
        right: 0px;
    }
    #pdf{
        direction: ltr;
        text-align: left;
        ol{
            li{
                text-align: left;
            }
        }
    }
    .sts-form .phnum-invalid .mat-input-element{
        background-position: left 13px center;
    }
    .tc-checkbox{
        text-align: right;
        padding-right: 30px;
        .custom-checkbox{
            right: 0px !important;
            left:  unset !important;
        }
    }
    .sts-table.variant2 table thead tr th.mat-header-cell:first-of-type{
        right: 13px;
    }
    .sts-table.variant2 table thead tr th:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .breadcrumb .breadcrumb-item:before{
        transform: rotate(-180deg);
    }
    .breadcrumb-item+.breadcrumb-item::before{
        float: right;
    }
    .country-selector{
        .mat-button-wrapper{
            display: flex;
            justify-content: end;
            span:nth-child(1){
                order: 2;
            }
            span:nth-child(2){
                order: 1;
                direction: ltr;
            }
        }
    }
    .ar-mobnumber-transform{
        direction: ltr;
        display: inline-block;
    }
    .pdf-input input{
        padding-right: 15px;
    }
    .sts-table.variant2 table tbody tr td.mat-cell:first-of-type{
        left: unset;
    }
    .sts-table.variant2 table tbody tr td{
        padding-right: 15px;
    }
    .sts-table.variant2 table tbody tr:nth-child(even) td,.sts-table.variant2 table tbody tr:nth-child(odd) td{
        border-right: none;
    }
    .sts-table.variant2 table tbody tr td.mat-cell:first-of-type::before{
        display: none;
    }
    .chatbot-container {
        left: 24px;
        right: unset !important;
    }
    .k-chat .k-message-box .k-button {
        transform: scaleX(1);
    }
    .full-screen-chatbot{
        left: 0px;
    }
    .sts-table.variant2 table tbody tr td.mat-cell:last-of-type::after{
        display: none;
    }
    .ps-3 {
        padding-right: 1rem !important;
        padding-left: 0px !important;
    }
    .me-md-5 {
        margin-right: unset !important;
    }

    .textarea-with-count .count {
      text-align: left !important;
    }
    .sts-landing .horizontal-text {
      left: auto;
      right: 9px;
    }
    .sts-landing .sts-carousel-award .left-nav, .sts-landing .owl-nav-style1 .left-nav {
      transform: rotate(360deg) !important;
    }
    .sts-landing .sts-carousel-award .right-nav, .sts-landing .owl-nav-style1 .right-nav {
      transform: rotate(180deg) !important;
    }
    img.phone-img {
      transform: rotate(270deg);
    }
    .landing-service .owl-stage-outer{
      position:relative;
      overflow:hidden;
      -webkit-transform:translate3d(0,0,0);
      direction: ltr;
     }

     .chatbot-form .btn-chatbot.me-3 {
        margin-left: 0px !important;
     }
     .container-custom {
      direction: rtl;
     }

     .submenu-links .mat-menu-content {
      right: auto;
      left: 0px !important;
      &:before{
        right: auto;
        left: 32px !important;
      }
     }
     .submenu-links .submenu-hoverbg {
      direction: rtl;
     }
     .profile-logout {
      direction: rtl;
     }

     .topbar {
      ul {
        li {
          .user-info {
            padding: 10px 2px 10px 14px;
          }
        }
      }
     }
     .pick-up-drop-off-time {
      direction: rtl;
     }

     .list-inline-item:not(:last-child) {
        margin-left: .5rem;
        margin-right: 0px;
    }
    .text-clr1,.topbar ul li a {
      font-weight: 600;
    }
    .back-button {
      transform: rotate(180deg);
    }
    .search-icon {
      // transform: rotate(275deg);
      margin-left: 10px !important;
    }

    // handburger Menu style changes for arabic in mobile added below
    .topbar {
      .menu {
        .navbar {
          justify-content: end;
        }
      }
    }
    .mobile-menu-modalbox {
      direction: rtl;
      @media(max-width: 992px){
        direction: rtl;
      }
    }
    .list-dot{
      left: inherit !important;
      right: 0px;
      top: 4px;
    }

    // dropdown arrow direction for arabic version changed below
    .mat-select-trigger {
      direction: rtl;
    }

    .spinner-style {
      .la-ball-scale-multiple {
          display: none !important;
      }
      img {
          width: 100px;
          margin-right: -75px;
          margin-left: 0px;
      }
      .loading-text {
          position: unset !important;
          transform: unset !important;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: transparent;
      }
  }

  .mat-flat-button.mat-button-disabled.mat-button-disabled{
    color: rgba(0, 0, 0, 0.75);
    }
    .sts-landing .topbar .login-btn button.login-btn-primary {
      @media(max-width: 767px){
        white-space: nowrap;
      }
    }
    .sts-landing .topbar .login-btn-scrolled button.login-btn-primary {
      @media(max-width: 767px){
        white-space: nowrap;
      }
    }
  }
