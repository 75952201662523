.sts-badge{
    &.variant-1{
        @include border-radius(4px);
        width: 2.438rem;
        height: 1rem;
        line-height: 1rem;
        background: #008E5B;
        text-align: center;
        @include fontsize-variant-1($font-size-10, $font-weight-medium, $white);
        &.clr-variant1{
            background: #D6751C;
        }
    }
    &.verified-badge{
        width: 6rem;
        height: 2rem;
        line-height: 2rem;
        // background: rgba(#0AC093, 10%);
        background: rgb(235 253 248);
        text-align: center;
        @include border-radius(8px);
        @include fontsize-variant-1($font-size-14, $font-weight-medium, #0AC093);
    }
    &.green-badge-sm{
        // width: 4rem;
        // height: 2rem;
        // line-height: 2rem;
        // background: rgba(#0AC093, 10%);
        background: rgb(235 253 248);
        text-align: center;
        @include border-radius(6px);
        @include fontsize-variant-1($font-size-12, $font-weight-medium, #0AC093);
        padding: 2px 7px;
    }
    &.variant-2{
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
    linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
    @include border-radius(100%);
    }
    &.variant-3{
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
        linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
    @include border-radius(16px);
    flex: 0 0 48px;
    }
    &.error-badge{
        background: rgba(#E11A3E,8%);
        @include border-radius(4px);
        padding: 5px 10px;
        @include fontsize-variant-1($font-size-12, $font-weight-normal, #E11A3E);
    }
    &.success-badge{
        background: rgba(#119666,8%);
        @include border-radius(4px);
        padding: 5px 10px;
        @include fontsize-variant-1($font-size-12, $font-weight-normal, #119666);
    }
    &.warning-badge{
        background: rgba(#EA9D09,8%);
        @include border-radius(4px);
        padding: 5px 10px;
        @include fontsize-variant-1($font-size-12, $font-weight-normal,
        #EA9D09);
    }
    &.variant-4{
        width: 3.875rem;
        height: 1.375rem;
        @include border-radius(4px);
        background: rgba(#050518, 10%);
        text-align: center;
        line-height: 1.375rem;
    }
    &.variant-5 {
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        background: linear-gradient(168.53deg, rgba(234, 255, 254, 0.5) 0%, rgba(201, 217, 241, 0.5) 97.46%),
            linear-gradient(168.98deg, #FFFFFF 1.41%, rgba(255, 255, 255, 0) 98.85%);
        @include border-radius(20px);
        &.card-image {
            flex: 0 0 80px;
        }
    }
}
.show-hide-btn{
    position: absolute;
    right: 10px;
    top: 40px;
    background: #eee;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 20px;
}
.error-msg{
background: #ff63471a;
border: 1px solid tomato;
border-radius: 6px;
padding: 8px;
color: tomato;
font-size: 14px;
}
.success-msg{
    background: #06a6801f;
    border-radius: 6px;
    padding: 8px;
    border: 1px solid #06a680;
    font-size: 14px;
    color: #06a680;
}