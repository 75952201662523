//profile-menu dropdown
.profile-menu {
    min-height: unset !important;
    max-height: unset !important;
    margin-top: 15px;
    min-width: 11rem !important;
    box-shadow: 0 0.9375rem 2.1875rem 0 rgba(35, 46, 154, .11);

    .mat-menu-item {
        height: unset;
        line-height: unset;
        @include fontsize-variant-1($font-size-12, $font-weight-normal, rgba($black-variant2, 0.8));
        display: flex;
        align-items: center;
        gap: 5px;
        font-family: $font-family-urbanist;

        &:hover {
            background: transparent;
        }
    }

    @media screen and (max-width: 767px) {
        margin-top: 3px;
    }
}

.submenu-links {
    overflow: visible !important;
    margin-top: 3rem;
    @media screen and (max-width:992px){
        margin-top: 1rem;
    }
    background: transparent;
    box-shadow: none;
    position: relative;
    .mat-menu-content{
        box-shadow: 0px 4px 45px 0px #1A2A610F;
        @include border-radius(20px);
        position: relative;
        padding: 0px !important;
        background: $white;
        width: 600px;
        position: absolute;
        right: 0px;
        @media screen and (max-width:992px){
            position: unset;
            width: 100%;
            .overflow-submenu{
                height: 200px;
                overflow-y: auto;
            }
        }
        &:before {
            position: absolute;
            top: -12px;
            right: 65px;
            border-bottom: 12px solid #fff;
            border-right: 8px solid transparent;
            border-left: 9px solid transparent;
            content: "";
            @media screen and (max-width:992px){
                right: unset;
                left: 20px;
            }
        }
    }
    &.variant-1{
        margin-top: 2rem;
        .mat-menu-content{
            width: 300px;
            padding: 20px !important;
        }
    }
    .submenu-hoverbg{
        padding: 10px;
        &:hover{
            background: rgba(#0977C5, 6%);
            @include border-radius(12px);
        }
    }
}
.isActiveStudent{
    border: 2px solid #0977C5;
    border-radius: 20px;
    background: rgba(#0977C5,6%);
    padding: 0.8rem;
}
.noActiveStudent{
    border-radius: 20px;
    background: rgba(#0977C5,6%);
    padding: 0.8rem;
}