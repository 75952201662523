html,
body {
    background: url("../../assets/images/Web-BG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    scroll-behavior: smooth;
}
.body-height-100 {
    height: 100%;
    top: 0px !important;
    position: relative;
}
.body-height-100vh {
    height: 100%;
    top: 0px !important;
    position: relative;
    @media screen and (max-width: 767px) {
        height: 100vh;
    }
}
.rm-body-height {
    height: unset;
}
.rm-height {
    height: 100%;
    @media(max-width: 380px) {
        height: unset;
    }
}
* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
.container-base {
    padding: 0 45px;
}
.container-base-variant1 {
    padding: 0 90px;
}
.container-base-variant2 {
    padding: 0 110px;
}
// spinner-style
.spinner-style {
    .la-ball-scale-multiple {
        display: none !important;
    }
    img {
        width: 100px;
        margin-left: -75px
    }
    .loading-text {
        position: unset !important;
        transform: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: transparent;
    }
}
//negative margins
.mt-n2 {
    margin-top: -1rem;
    @media(max-width: 992px) {
        margin-top: 0px;
    }
}
//common css
.date-picker-v1 {
    position: relative;
    .mat-datepicker-toggle {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
.mandatory-contact-details {
    border-radius: 30px;
    box-shadow: 0px 20px 50px 0px #1A2A610D;
    background: linear-gradient(160.7deg, #E1F2FF 5.36%, rgba(174, 221, 255, 0.3) 93.99%);
}
//country code
.country-list-button:nth-last-child(1),
.country-list-button:nth-last-child(2) {
    display: none;
}
.mat-divider-horizontal {
    display: none !important;
}
//chatbot-float
.chatbot-float {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
}
//edit-user-icon common css
.edit-user-icon {
    position: absolute;
    bottom: 0px;
    right: -5px;
}
//chat overflow control related to body tag
.bodyoverflow-hidden {
    overflow: hidden;
}
//language-switcher
.language-switcher {
    width: 100px;
    @media(max-width: 767px) {
      padding: 0px !important;
    }
    .sts-form {
        position: relative;
        img {
            position: absolute;
            top: 8px;
            left: 10px;
        }
        .mat-select {
            padding-left: 16px;
            height: 32px;
            line-height: 40px;
            border: none;
            width: 100%;
            &:focus {
                border: none;
            }
            @media(max-width: 767px) {
              line-height: 36px;
            }
        }
    }
    .mat-form-field{
        border: 2px solid #05051882;        ;
        border-radius: 50px;
        width: 105px !important;
        height: 45px;
        @media(max-width: 767px) {
          border: 1px solid #05051882;
          width: 94px !important;
          height: 39px;
        }
    }
    .mat-select-value{
        max-width: 100px;
    }
    .mat-select-arrow{
        width: 10px !important;
        height: 9px !important;
        background-size: contain !important;
    }
}
//landing-page-translation-button
.landing-page-translation-button {
    .sts-form .mat-select .mat-select-arrow {
        background-color: transparent;
        background: url(../../assets/images/down-arrow.svg);
    }
    .mat-select{
        width: 100%;
        border: 2px solid rgba(255, 255, 255, 0.2784313725) !important;
        border-radius: 50px !important;
        height: unset !important;
    }
}
//attachment label
.attachment {
    mat-label {
        line-height: 110px;
    }
}
.landing-page-translation-button {
    .mat-select-min-line {
        color: #ffffff9c;
        font-size: 1rem;
        font-weight: 500;
    }
    .mat-select-arrow {
        background: url("../../assets/images/down-white.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: 15px !important;
        margin: 0px !important;
        position: relative !important;
        top: 1px;
        right: -1px;
        @media(max-width: 767px) {
          right: -2px;
        }
    }
}
.fixed-top {
    .landing-page-translation-button {
        .mat-select-min-line {
            color: rgba(5, 5, 24, 0.6);
            font-size: 1rem;
            font-weight: 500;
        }
        .mat-select-arrow {
            background: url("../../assets/images/down-arrow.png") !important;
            margin-top: -5px !important;
            position: relative !important;
        }
    }
}
//for payment refund page
.leftside-content-width {
    overflow-x: hidden;
}
//or-style
.or-style{
    color: #00000080;
    // border-top: 2px solid #DADCEA;
    position: relative;
    &:before{
        position: absolute;
        top:0;
        left: 18%;
        right: 0px;
        background: #DADCEA;
        height: 1.3px;
        width: 63%;
        content: "";
    }
}
//language-panel
.language-panel{
    box-shadow: 0px 4px 45px 0px rgba(26, 42, 97, 0.0588235294) !important;
    border-radius: 20px !important;
    width: 200px;
    margin-top: 50px;
    padding: 20px;
    height: 180px;
    padding-top: 24px !important;
    @media(max-width: 767px) {
      width: 180px;
      height: 144px;
    }
    .mat-option{
        background: #f1f7fc;
        border-radius: 15px;
        margin-bottom: 12px;
        height: 60px !important;
        @media(max-width: 767px) {
          height: 44px !important;
        }
        &.lang-ar {
          text-align: right;
        }
    }
    .mat-option.mat-selected:not(.mat-option-multiple){
        border:2px solid #0977C5;
        background: #f1f7fc !important;
        position: relative;
        height: 60px;
        &:after{
            position: absolute;
            right:15px;
            background: url(../../assets/images/tick-blue.svg);
            content: "";
            width: 24px;
            height: 24px;
        }
    }
}
