//Font mixins
@mixin fontsize-variant-1($font-size, $font-weight, $color) {
    @if ($font-size !=null) {
        font-size: ($font-size);
    }

    @if ($font-weight !=null) {
        font-weight: ($font-weight);
    }

    @if ($color !=null) {
        color: ($color);
    }
}


@mixin border-radius($pixel) {
    border-radius: $pixel;
}

// Text-overflow mixins
@mixin text-overflow($line) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @if ($line != null) {
        -webkit-line-clamp: $line;
    }
}

// Custom scroll bar for Chrome and IE

@mixin scrollbars($size, $foreground-color, $background-color) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
    }

    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
      background: $background-color;
    }

    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
}

// Vertical scrollbar

@mixin scrollbars-y($size, $foreground-color, $background-color) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width: 8px;
      height: $size;
    }

    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
      border-right: 4px transparent solid;
      border-top:4px solid transparent;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    // &::-webkit-scrollbar {
    //   width: 5px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   border: 4px solid rgba(0, 0, 0, 0);
    //   background-clip: padding-box;
    //   border-radius: 100px;
    //   background-color: #AAAAAA;
    //   border-left: 10px solid transparent;
    // }

    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }

    // new css scrollbar
    // &::-webkit-scrollbar {
    //   width: 5px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   border: 4px solid rgba(0, 0, 0, 0);
    //   background-clip: padding-box;
    //   border-radius: 9999px;
    //   background-color: #AAAAAA;
    // }
}