@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    font-family: $font-family-urbanist !important;
}
// $font-family-roboto
.roboto-font{
    font-family: $font-family-roboto;
}
// title size 10
.fs10-variant1 {
    @include fontsize-variant-1($font-size-10, $font-weight-medium, $white);
}
.fs10-variant2{
    @include fontsize-variant-1($font-size-10, $font-weight-normal, rgba(#111111, 80%));
}
//title size 11
.fs11-variant1 {
    @include fontsize-variant-1($font-size-11, $font-weight-semibold, rgba($base-black-color, 75%));
}
// title size 16
.fs16-variant1 {
    @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($base-black-color, 0.6));
}
.fs16-variant2 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, $base-black-color);
}
.fs16-variant3 {
    @include fontsize-variant-1($font-size-16, $font-weight-medium, rgba($black-variant1, 0.6));
}
.fs16-variant4 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, $black-variant1);
}
.fs16-variant5 {
    @include fontsize-variant-1($font-size-16, $font-weight-normal, rgba($white, 0.8));
}
.fs16-variant6 {
    @include fontsize-variant-1($font-size-16, $font-weight-semilight, rgba($white,0.6));
}
.fs16-variant7 {
    @include fontsize-variant-1($font-size-16, $font-weight-semilight, $white);
}
.fs16-variant8 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, rgba($black-variant1, 0.8));
    line-height: 22.2px;
}
.fs16-variant9 {
    @include fontsize-variant-1($font-size-16, $font-weight-thin, rgba($blue-variant3,0.6));
    font-family: $font-family-dmsans;
}
.fs16-variant10 {
    @include fontsize-variant-1($font-size-16, $font-weight-normal, rgba($black-variant1, 0.4));
}
.fs16-variant11 {
    @include fontsize-variant-1($font-size-16, $font-weight-normal, $black-variant1);
}
.fs16-variant12 {
    @include fontsize-variant-1($font-size-16, $font-weight-medium, $black-variant1);
}
.fs16-variant13 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, rgba($base-black-color, 0.6));
}
.fs16-variant14 {
    @include fontsize-variant-1($font-size-16, $font-weight-bold, $black-variant1);
}
.fs16-variant15 {
    @include fontsize-variant-1($font-size-16, $font-weight-normal, rgba($black-variant1, 0.6));
}
.fs16-variant16 {
    @include fontsize-variant-1($font-size-14, $font-weight-semibold, #F7537A);
}
// title size 14
.fs14-variant1 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($base-black-color, 0.8));
}
.fs14-variant2 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, $black-variant1);
}
.fs14-variant3 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, $white);
}
.fs14-variant4 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($base-black-color, 0.6));
}
.fs14-variant5 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($base-black-color, 0.6));
}
.fs14-variant6 {
    @include fontsize-variant-1($font-size-14, $font-weight-medium, $base-black-color);
}
.fs14-variant7 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, rgba($white, 0.6));
}
.fs14-variant8 {
    @include fontsize-variant-1($font-size-14, $font-weight-medium, rgba($base-black-color, 0.6));
}
.fs14-variant9 {
    @include fontsize-variant-1($font-size-14, $font-weight-medium, $base-blue-color);
}
.fs14-variant10 {
    @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-black-color);
}
.fs14-variant11 {
    @include fontsize-variant-1($font-size-14, $font-weight-semibold, rgba($black-variant2, 60%));
}
.fs14-variant12 {
    @include fontsize-variant-1($font-size-14, $font-weight-medium, rgba($white, 75%));
}
.fs14-variant13 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, #F7537A);
}
.fs14-variant14 {
    @include fontsize-variant-1($font-size-14, $font-weight-normal, $base-blue-color);
}
.fs14-variant15 {
    @include fontsize-variant-1($font-size-14, $font-weight-semibold, #CBE9FF);
}
// title size 12
.fs12-variant1 {
    @include fontsize-variant-1($font-size-12, $font-weight-normal, rgba($base-black-color, 0.6));
}
.fs12-variant2 {
    @include fontsize-variant-1($font-size-12, $font-weight-medium, $base-black-color);
}
.fs12-variant3 {
    @include fontsize-variant-1($font-size-12, $font-weight-medium, rgba($base-black-color, 60%));
}
.fs12-variant4 {
    @include fontsize-variant-1($font-size-12, $font-weight-semibold, rgba($base-black-color, 75%));
}
.fs12-variant5 {
    @include fontsize-variant-1($font-size-12, $font-weight-semibold, rgba($base-black-color, 80%));
}
.fs12-variant6 {
    @include fontsize-variant-1($font-size-12, $font-weight-medium, $white);
}
.fs12-variant7 {
    @include fontsize-variant-1($font-size-12, $font-weight-semibold, rgba($base-black-color, 60%));
}
.fs12-variant8 {
    @include fontsize-variant-1($font-size-12, $font-weight-normal, $base-black-color);
}
.fs12-variant9 {
    @include fontsize-variant-1($font-size-12, $font-weight-normal, rgba($white, 60%));
}
// title size 18
.fs18-variant1 {
    @include fontsize-variant-1($font-size-18, $font-weight-medium, rgba($black-variant1, 0.6));
}
.fs18-variant2 {
    @include fontsize-variant-1($font-size-18, $font-weight-bold, $black-variant1);
}
.fs18-variant3 {
    @include fontsize-variant-1($font-size-18, $font-weight-semibold, $black-variant1);
}
.fs18-variant4 {
    @include fontsize-variant-1($font-size-18, $font-weight-normal, $white);
}
.fs18-variant5 {
    @include fontsize-variant-1($font-size-18, $font-weight-normal, rgba($white,0.6));
    line-height: 28px;
}
.fs18-variant6 {
    @include fontsize-variant-1($font-size-18, $font-weight-normal, rgba($black-variant2, 0.6));
    line-height: 28px;
}
.fs18-variant7 {
    @include fontsize-variant-1($font-size-18, $font-weight-semibold, $white);
}
.fs18-variant8 {
    @include fontsize-variant-1($font-size-18, $font-weight-medium, $black-variant2);
}
.fs18-variant9 {
    @include fontsize-variant-1($font-size-18, $font-weight-medium, rgba($white, 0.8));
}
.fs18-variant10 {
    @include fontsize-variant-1($font-size-18, $font-weight-semilight, rgba($white,0.6));
    line-height: 32px;
}
.fs18-variant11 {
    @include fontsize-variant-1($font-size-18, $font-weight-normal, $black-variant1);
}
.fs18-variant12 {
    @include fontsize-variant-1($font-size-18, $font-weight-semibold, rgba($black-variant1, 0.6));
}
// title size 20
.fs20-variant1 {
    @include fontsize-variant-1($font-size-20, $font-weight-semibold, $black-variant1);
}
.fs20-variant2 {
    @include fontsize-variant-1($font-size-20, $font-weight-normal, $white);
}
.fs20-variant3 {
    @include fontsize-variant-1($font-size-20, $font-weight-normal, rgba($black-variant1, 0.6));
}
.fs20-variant4 {
    @include fontsize-variant-1($font-size-20, $font-weight-bold, $black-variant2);
}
.fs20-variant5 {
    @include fontsize-variant-1($font-size-20, $font-weight-semibold, $white);
}
.fs20-variant6 {
    @include fontsize-variant-1($font-size-20, $font-weight-bold, $white);
}
.fs20-variant7 {
    @include fontsize-variant-1($font-size-20, $font-weight-medium, $base-black-color);
}
// title size 22
.fs22-variant1 {
    @include fontsize-variant-1($font-size-22, $font-weight-bold, $black-variant2);
}
.fs22-variant2 {
    @include fontsize-variant-1($font-size-22, $font-weight-semibold, $white);
}
.fs22-variant3 {
    @include fontsize-variant-1($font-size-22, $font-weight-semibold, $black-variant2);
}
// title size 24
.fs24-variant1 {
    @include fontsize-variant-1($font-size-24, $font-weight-bold, $black-variant1);
}
.fs30-variant1 {
    @include fontsize-variant-1($font-size-30, $font-weight-bold, $black-variant1);
}
.fs44-variant1 {
    @include fontsize-variant-1($font-size-45, $font-weight-semibold, $white);
    line-height: 55px;
}
.fs40-variant1 {
    @include fontsize-variant-1($font-size-40, $font-weight-bold, $black-variant1);
}
.fs42-variant1 {
    @include fontsize-variant-1($font-size-40, $font-weight-bold, $white);
    line-height: 50px;
    @media screen and (max-width: 767px){
        @include fontsize-variant-1($font-size-32, $font-weight-bold, $white);
        line-height: 42px;
    }
}
.fs42-variant2 {
    @include fontsize-variant-1($font-size-40, $font-weight-bold, $black-variant2);
    line-height: 50px
}
//title size 54
.fs52-variant1 {
    @include fontsize-variant-1($font-size-52, $font-weight-semibold, $white);
    line-height: 62px;
}
//title size 130
.fs130-variant1 {
    @include fontsize-variant-1($font-size-130, $font-weight-bold, rgba($white,0.1));
    font-family: $font-family-dmsans;
}
//title size 150
.fs150-variant1 {
    @include fontsize-variant-1($font-size-150, $font-weight-bold, rgba($white,0.1));
    font-family: $font-family-dmsans;
}
//title size 100
.fs100-variant1 {
    @include fontsize-variant-1($font-size-100, $font-weight-bold, rgba($white,0.1));
    font-family: $font-family-dmsans;
}
// LINKS
.link-title-variant1 {
    @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-blue-color !important);
}
.link-title-variant2 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, $base-blue-color);
    text-decoration: none;
    &.text-decoration-u{
        text-decoration: underline
    }
}
.link-title-variant3 {
    @include fontsize-variant-1($font-size-14, $font-weight-medium, $base-blue-color);
}
.link-title-variant4 {
    @include fontsize-variant-1($font-size-16, $font-weight-semilight, $white);
    text-decoration: none;
    &.text-decoration-u{
        text-decoration: underline
    }
}
.link-title-variant5 {
    @include fontsize-variant-1($font-size-18, $font-weight-semibold, $base-blue-color);
}
.link-title-variant6 {
    @include fontsize-variant-1($font-size-16, $font-weight-normal, $base-blue-color);
    text-decoration: none;
    &.text-decoration-u{
        text-decoration: underline;
        text-decoration-thickness: 1px
    }
}
.link-title-variant7 {
    @include fontsize-variant-1($font-size-16, $font-weight-semibold, $white);
    text-decoration: none;
    &.text-decoration-u{
        text-decoration: underline
    }
}
.border-radius-6 {
    @include border-radius($border-radius6);
}
.border-radius-8 {
    @include border-radius($border-radius8);
}
.border-radius-12 {
    @include border-radius($border-radius12);
}
.border-radius-16 {
    @include border-radius($border-radius16);
}
.border-radius-20 {
    @include border-radius($border-radius20);
}
// backgrounds
.error{
    @include fontsize-variant-1($font-size-14, $font-weight-normal, red);
}
.required{
    color: red;
}
.truncate-text-1lines{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.truncate-text-2lines{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
//overflow ellipsis
.overflow-ellipsis {
    @include text-overflow(1);
}
.overflow-ellipsis-variant1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(98%);
}
.overflow-ellipsis-variant2{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cursor-pointer{
    cursor: pointer;
}
.cursor-default{
    cursor: default;
}
.flex-50{
    flex: 0 0 50%;
}
//bg colors
.bg-color1{
    background: rgba($base-blue-color, 10%);
}
.bg-color2{
    background: rgba(#050518, 4%);
}
.bg-color3{
    background: rgba($base-blue-color, 5%);
}
.bg-color4{
    background: rgba($base-blue-color, 8%);
}
.bg-color5{
    background: $base-blue-color;
}
.bg-color6{
background: rgba(#E11A3E,8%);
}
.bg-linear-color1{
    background: linear-gradient(160.7deg, #E1F2FF 5.36%, rgba(174, 221, 255, 0.3) 93.99%);
}
// text colors
.text-clr1{
    color: $base-blue-color;
}
.text-clr2{
    color: #E11A3E;
}
.text-clr3{
    color: #daf106;
}
// Scroll Input Size
$scroll-width-xs: 5px;
$scroll-width-sm: 7px;
$scroll-width-md: 10px;
$scroll-width-0: 0px;
$scroll-bg-1: #c5c5d5;
$transparent: transparent;
.scroll-y{
    @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
}
// custom-checkbox
.mat-ripple-element{
    display: none !important;
}
.custom-checkbox {
    &.mat-checkbox-checked {
        .mat-checkbox-background {
            background-color: $base-blue-color;
        }
    }
    &.variant-1{
        width: 20px;
        transition: unset;
        height: 20px;
        @include border-radius(4px);
        border: 1px solid rgba(#050518, 60%);
        &.mat-checkbox-checked {
            .mat-checkbox-background {
                width: 20px;
                height: 21px;
                border-radius: 4px;
                top: -5px;
                left: -1px
            }
        }
        .mat-checkbox-frame{
            display: none;
        }
    }
}
//radio button
.mat-radio-button{
    font-family: $font-family-urbanist;
    .mat-radio-outer-circle{
        border-width: 1px;
    }
    &.mat-radio-checked{
        .mat-radio-inner-circle{
            background-color: $base-blue-color;
        }
        .mat-radio-outer-circle{
            border-color: $base-blue-color !important;
        }
    }
}
//mat calendar
.mat-calendar{
    font-family: $font-family-urbanist;
    .mat-button{
        font-family: $font-family-urbanist;
    }
    .mat-calendar-table-header th{
        @include fontsize-variant-1($font-size-14, $font-weight-semibold, $base-black-color);
    }
    .mat-calendar-body-selected{
        background-color: $base-blue-color;
    }
    .mat-calendar-controls{
        margin-top: 0px;
    }
    .mat-calendar-body-cell{
        .mat-calendar-body-today{
            background-color: rgb(9 119 197 / 16%) !important;
        }
        &:hover{
            .mat-calendar-body-cell-content{
                background-color: rgb(9 119 197 / 16%) !important;
            }
        }
    }
}
//chatbot
.chatbot{
    z-index: 10;
    bottom: 40px;
    right: 20px;
}
//in-active style
.in-active{
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
    &.in-active-name{
        color: rgba(#050518, 60%);
        opacity: 1;
    }
}

//pdf styles
.custom-pdf{
    width: 100%;
    height: 400px;
    .ng2-pdf-viewer-container{
        @include scrollbars-y($scroll-width-xs, $scroll-bg-1, $white);
        padding-right: 20px;
    }
}
//miscellaneous css
.mat-menu-panel{
    max-width: 100% !important;
}
// list-dot
.list-dot{
    background: $base-blue-color;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    position: absolute;
    left: 0px;
}
.list-dot-sm{
    background: #0977C5;
    width: 7px;
    height: 7px;
    border-radius: 100px;
    position: absolute;
    // top: 7px;
    left: 0px;
}
// padding
.px-6{
        padding-right: 8rem!important;
        padding-left: 8rem!important;
}
// breadcrumb
.breadcrumb{
    margin-bottom: 5px;
}
.inactive-text{
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
}
//pdf iframe styles terms and condition
.terms-condition-pdf-iframe{
    width: 100%;
    height: 800px;;
}
.news-widget-carousel {
    .owl-stage{
        padding-left: 0px !important;
    }
    .owl-item {
        padding-right: 0px !important;
    }
}
.blue-dots-carousel{
    .owl-dots {
        .owl-dot {
            span {
                background: rgba(#0977C5, 20%) !important;
            }
            &.active {
                span {
                    background: #0056A6 !important;
                }
            }
        }
    }
}
.news-widget-carousel{
    .owl-dot span{
        margin: 5px 4px !important;
    }
}
.mb-24, .my-24 {
    margin-bottom: 1.5rem;
}
.h-45{
    height: 45px;
}
.selectedmenu{
    a{
        @include fontsize-variant-1($font-size-16, $font-weight-bold !important, $base-blue-color !important);
    }
}
// line heights
.lh-17{
    line-height: 17px;
}
// /word-break
.word-break{
    word-break: break-word;
}