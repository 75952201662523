// Large desktops, laptops
@media screen and (min-width: 1200px) {

}
@media only screen and (min-width: 993px) and (max-width: 1199px){
    .mobile-btn{
        display: flex;
        justify-content: center;
    }
 .mobilenumber-field{
    width: 50%;
 }
 .contact-widget{
    .mat-btn-primary{
        white-space: normal;
        height: unset !important;
    }
 }
 .fullwidth-btn-laptop{
    display: flex;
    flex-direction: column;
    gap: 10px;
    button{
        width: 100%;
        margin: 0px !important;
    }
 }
 .long-label{
    .mat-form-field-label{
        line-height: 132px !important;
    }
    .mat-form-field-suffix{
        top: 59px !important;
    }
}
}
// small Laptop, tab
@media screen and (max-width: 992px){
    .sts-main-content{
        padding-top: 130px;
        .lr-content{
            .lr-inner-content{
                min-height: unset;
                .map-overflow{
                    height: unset;
                }
            }
            .container-base-variant1 {
                padding: 80px 30px;
                text-align: center;
            }
        }
        .schedule-user {
            background: $white;
            padding: 20px;
            @include border-radius(15px);
            box-shadow: 0px 2px 20px 0px #0000000A;
            .mat-btn-secondary,.mat-btn-primary{
                min-width: unset !important;
            }
            .profile-image-m0{
                margin-top: 0px;
            }
        }
        .container-base-variant1{
            padding: 0px;
        }
        .sts-form{
            .form-label{
                font-size: 0.9rem;
            }
            .mat-form-field{
                .mat-input-element{
                    background: $white;
                }
            }
            .mat-select{
                background: $white;
            }
            .mat-select .mat-select-arrow{
                width: 19px;
                height: 19px;
                position: relative;
                right: -12px;
            }
        }
        .full-width-btn{
            .mat-flat-button{
                min-width: unset !important;
                padding: 0.375rem 2.25rem !important;
            }
        }
    }
    .mobilenumber-field{
        width: 70%;
    }
    .sidebar{
        width: 100%;
        min-height: unset;
        margin-top: 20px;
        ul{
            height: unset;
        }
    }
    .sts-tab{
        &.tab-variant2{
                .mat-tab-header-pagination{
                    display: none;
                }
                .mat-tab-label{
                    padding-right: 0px !important;
                    white-space: unset;
                    height: unset;
                }
                .mat-label-text{
                    border: none !important;
                    background: transparent !important;
                    padding: 0px !important;
                    &:before{
                       width: calc(100% - -20px) !important;
                        margin: auto;
                        top: 21px !important;
                        content: "";
                        left: unset !important;
                    }
                }
                .mat-label-number{
                    display: block;
                    margin: auto;
                    background: #eef6ff;
                    z-index: 1;
                    position: relative;
                }
                .mat-tab-header{
                    background: transparent;
                    padding: 0px;
                    border-radius: 0px;
                    overflow: visible;
                }
                .mat-tab-label-content{
                    white-space: unset;
                }
                .mat-tab-label-container{
                    overflow: visible;
                }
                .mat-tab-labels{
                    align-items: baseline;
                }
        }
    }
    .fullwidth-btn{
        display: flex;
        flex-direction: column;
        gap: 10px;
        button{
            width: 100%;
            margin: 0px !important;
        }
    }
    .fullwidth-btn-md{
        display: flex;
        flex-direction: row;
        button{
            width: unset;
        }
    }
    .sibling-card-height-no-message{
        height: unset;
    }
    .search-input.size-lg{
        width: 100% !important;
    }
    .col-quick-link{
        display: none;
    }
    .table-container {
        width: 100%;
        overflow-x: auto;
        table{
            thead{
                th{
                    min-width: 150px;
                }
            }
        }
      }
}
@media screen and (max-width: 767px){
    .container-base{
        padding: 0px 25px;
        .sts-main-content {
            &.no-breadcrumb{
                padding-top: 80px;
            }
            .lr-content{
                .container-base-variant1 {
                    padding: 10px;
                }
                .lr-rightside{
                    background-color: transparent !important;
                }
                .lr-leftside{
                    border-radius: 24px;
                }
            }
            .schedule-user {
                .profile-image{
                    margin-top: -4rem;
                }
            }
            .sts-form{
                .search-input{
                    &.size-lg{
                        width: unset;
                    }
                }
                .otp-input-box{
                    width: 31px;
                    height: 31px;
                }

                .customPlaceholder{
                    .mat-form-field-label{
                        top: 7.5rem;
                    }
                    .mat-form-field-label-wrapper span{
                        left: 0px;
                    }
                }
                .ngx-mat-tel-input-container {
                    // display: unset;
                    gap: 8px;
                    .country-selector{
                        display: block;
                        width: 80px !important;
                        background-position: right 3px center;
                        padding-left: 3px;
                        background-size: 18px;
                        .country-selector-code{
                            font-size: 0.8rem;
                        }
                    }
                    input{
                        font-size: 13px !important;
                    }
                }
            }
            .sts-tab{
                &.tab-variant2{
                    .border-left{
                        border: none;
                    }
                }
            }
            .full-width-btn{
                .mat-flat-button.mat-button-base{
                    width: 100%;
                }
            }
        }
    }
    .cdk-overlay-pane{
        max-width: unset !important;
        min-width: unset !important;
    }
    .mat-expansion-panel-header{
        align-items: start !important;
        .mat-expansion-indicator{
            margin-top: 5px;
        }
        &[aria-expanded="false"]{
            .mat-expansion-indicator{
                margin-top: -2px;
            }
        }
    }
    .mobilenumber-field{
        width: 100%;
    }
    .sts-tab{
        &.tab-variant2{
            .mat-tab-labels{
                gap: 15px;
            }
                .mat-label-text{
                    border: none !important;
                    background: transparent !important;
                    padding: 0px !important;
                }
        }
    }
    .mobile-btn{
        gap: 10px;
        display: flex;
        flex-direction: column;
        button{
            margin-left: 0px !important;
            margin-right: 0px !important;
            width: 100%;
        }
    }
    .droplocation-map{
        bottom: 40px !important;
        left: 10px;
    }
    .mb-border-bottom-variant1{
        border-bottom: 1px solid rgba(#050518, 0.1);
    }
    .mb-rm-border{
        border: none;
    }
    .mobile-inner-col{
        .col{
            flex: unset;
        }
    }
    .rm-position{
        position: unset !important;
    }
    .fullwidth-btn-md{
        display: flex;
        flex-direction: column;
        gap: 10px;
        button{
            width: 100%;
            margin: 0px !important;
        }
    }
    .long-label{
        .mat-form-field-label{
            // line-height: 132px !important;
        }
        .mat-form-field-suffix{
            // top: 59px !important;
        }
    }
    .mb-p-0{
        padding: 0px;
    }
    .notification-tab{
        .mat-tab-header-pagination-before{
            display: none !important;
        }
        .mat-tab-header-pagination-after{
            display: none !important;
        }
        .mat-tab-label{
            padding: 0px 9px !important;
        }
    }
    .pdf-input{
        strong{
            &nbsp{
              display: none;
            }
        }
    }
}
@media screen and (max-width: 450px){
    .prefilled-schoolname{
        &.mat-select-disabled{
            height: unset;
            line-height: unset;
            padding: 10px;
            .mat-select-value-text,.mat-select-value{
                white-space: unset;
                overflow: visible;
                text-overflow: unset;
            }
            .mat-select-trigger{
                line-height: 20px;
            }
        }
    }
}

// miscellaneous
// 1100px
@media(max-width: 1400px) and (min-width: 1000px) {
    .home-school-section {
        width: 200px !important;

        .home {
            &::before {
                right: -29px !important;
                width: 64% !important;
            }
        }

        .school {
            &:after {
                left: -29px !important;
                width: 64% !important;
            }
        }
    }

    .profile-pic-info {
        width: 70%;
    }

    .home-school-images {
        gap: 6px !important;

        img {

            &:nth-child(1),
            &:nth-child(3) {
                width: 28px;
                height: 28px;
                ;
            }

            &:nth-child(2) {
                width: 145px !important;
            }
        }
    }

    // .profile-widget-content-height{
    //     height: 170px;
    //     overflow-y: auto;
    //     padding-bottom: 15px
    // }
    .container-base-variant1,
    .container-base-variant2 {
        padding: 0 70px;
    }

    .settings-card {
        width: 16.625rem !important;
    }
}
@media(max-width: 1250px) and (min-width: 1000px){
    .home-school-section {
        width: 145px !important;
        .busImage{
            img{
                width: 40px !important;
                height: 40px !important;
            }
        }

        .home {
            font-size: 10px;
            &::before {
                right: -22px !important;
    width: 64% !important;
    top: 8px !important;
            }
        }

        .school {
            font-size: 10px;
            &:after {
                left: -22px !important;
                width: 64% !important;
                top: 8px !important;
            }
        }
    }
    .profile-pic-info{
        width: 54%;
    }
}
@media(max-width: 767px) {
    .uae-popup {
        .mat-dialog-container {
          width: 40%;
          margin: auto;
          .owl-carousel {
            &.owl-drag {
              .owl-item {
                width: 420px !important;
              }
            }
          }
        }
      }
}
.custom-modalbox .fullwidth-btn button:first-child {
  margin-right: 1rem !important;
  margin-left: 0px !important;
  @media(max-width: 767px) {
    margin-right: 0px;
    margin-left: 0px !important;
  }
}

.sts-table.variant3 table tbody tr td {
  border: 0px solid transparent;
}


